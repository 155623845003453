import { computed, onMounted, onUpdated, Ref, ref, Slots } from '@vue/runtime-core';

export function useHasSlot(
	slotName: string,
	slots: Slots,
	optionalCondition?: (hasSlot: boolean) => boolean
): Ref<boolean> {
	const hasSlot = ref(false);
	const updateHasSlot = () => {
		hasSlot.value = !!slots[slotName];
	};

	onMounted(updateHasSlot);
	onUpdated(updateHasSlot);

	return computed(() => {
		if (!optionalCondition) return hasSlot.value;

		return optionalCondition(hasSlot.value);
	});
}
