<template>
	<form-field v-slot="{ field, errors }" v-model="model" v-bind="$props">
		<base-checkbox v-bind="{ ...field, ...$attrs }" :has-error="!!errors.length">
			<template v-if="hasLegend" #legend>
				<slot name="legend" />
			</template>

			<template #label="data">
				<slot name="label" v-bind="data">
					<span v-html="data.label" />
				</slot>
			</template>
		</base-checkbox>
	</form-field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { useFormFieldWrapper } from '@/common/validation/composables';
import { ValidationRule } from '@/common/validation/helpers';
import { CheckboxValue } from '@/components/base/checkbox/BaseCheckbox.vue';
import { useHasSlot } from '@/composables';

export default defineComponent({
	inheritAttrs: false,

	props: {
		name: {
			type: String,
			required: true
		},

		rules: {
			type: [String, Function, Array, Object] as PropType<ValidationRule>,
			default: () => []
		},

		modelValue: {
			type: [Array, Boolean] as PropType<CheckboxValue[] | boolean>,
			default: () => []
		}
	},

	emit: ['update:modelValue'],

	setup(props, { emit, slots }) {
		return {
			hasLegend: useHasSlot('legend', slots),

			...useFormFieldWrapper(props, emit)
		};
	}
});
</script>
