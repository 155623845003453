import { createRouter, createWebHistory } from 'vue-router';

import middlewares from './middlewares';
import routes from './routes';

declare module 'vue-router' {
	// example of extending VueRouter meta interface with your custom fields
	// interface RouteMeta {
	// ...
	// }
}

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

middlewares.forEach(middleware => router.beforeEach(middleware));

export default router;
