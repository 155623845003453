import axios from 'axios';

declare module 'axios' {
	export interface AxiosRequestConfig {
		suppressToken?: boolean;
	}
}

let requestLocale = 'en';
export function setLocale(locale: string): void {
	requestLocale = locale;
}

axios.defaults.baseURL = process.env.VUE_APP_API_URL || process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = process.env.VUE_APP_TIMEOUT;
axios.defaults.headers.common = {
	Accept: 'application/json, multipart/form-data'
};
axios.interceptors.request.use(
	config => {
		config.headers['Accept-Language'] = requestLocale;

		return config;
	},
	error => Promise.reject(error)
);
