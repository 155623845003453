import { watch } from 'vue';
import { Plugin } from 'vuex';

import { ANIMAL_TYPE_KEY, ANSWERS_KEY, CURRENT_QUESTION_KEY } from '@/constants/storage/constants';

import { questionsMutations } from '../modules/questions';
import state from '../modules/questions/state';

export const syncQuestionnaire: Plugin<{ questions: ReturnType<typeof state> }> = store => {
	watch(
		() => store.state.questions.type,
		() => {
			syncQuestionnaireState();
		}
	);

	watch(
		() => store.state.questions.answers,
		() => {
			syncQuestionnaireState();
		},
		{ deep: true }
	);

	function syncQuestionnaireState() {
		const questionsState = store.state.questions;

		if (!questionsState.type) {
			questionsMutations.clearLocalStorage();

			return;
		}

		localStorage.setItem(ANIMAL_TYPE_KEY, questionsState.type);
		localStorage.setItem(ANSWERS_KEY, JSON.stringify(questionsState.answers));
		localStorage.setItem(CURRENT_QUESTION_KEY, `${questionsState.currentQuestionIndex}`);
	}
};
