import { registerStore } from '@owlsdepartment/vuex-typed';
import { createStore, StoreOptions } from 'vuex';

export function initStore<T>(options: StoreOptions<T>) {
	const store = createStore(options);

	registerStore(store);

	return store;
}
