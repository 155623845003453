import { defineMutations } from '@owlsdepartment/vuex-typed';
import { isString } from 'lodash';

import { ANIMAL_TYPE_KEY, ANSWERS_KEY, CURRENT_QUESTION_KEY } from '@/constants/storage/constants';
import { AnswerModel, AnswerValue } from '@/models/AnswerModel';

import state from './state';
import { QuestionnaireType } from './types';

const mutations = defineMutations(state, {
	setQuestionnaireType(state, type: QuestionnaireType) {
		if (state.type !== type) {
			state.answers = [];
		}

		state.type = type;
	},

	setAnswer(state, { id, value }: { id: number; value: AnswerValue }) {
		const answer = state.answers.find(a => a.id === id);

		if (answer) {
			answer.answer = value;

			return;
		}

		state.answers.push({ id, answer: value });
	},

	setNextCurrent(state) {
		state.currentQuestionIndex += 1;
	},

	setPrevCurrent(state) {
		if (state.currentQuestionIndex === 0) {
			return;
		}

		state.currentQuestionIndex -= 1;
	},

	resetQuestionnaire(state) {
		state.type = '';
		state.answers = [];
		state.currentQuestionIndex = 0;
	},

	restoreFromLocalStorage(state) {
		const answers = localStorage.getItem(ANSWERS_KEY);
		const storage = {
			type: localStorage.getItem(ANIMAL_TYPE_KEY) ?? '',
			answers: answers ? JSON.parse(answers) : [],
			currentQuestion: +(localStorage.getItem(CURRENT_QUESTION_KEY) ?? 0)
		};

		if (
			storage.type === state.type &&
			Array.isArray(storage.answers) &&
			storage.currentQuestion <= storage.answers.length
		) {
			state.answers = storage.answers.map(a => {
				if (isString(a.answer)) {
					return { ...a, answer: new Date(a.answer) };
				}

				return a;
			}) as AnswerModel[];
			state.currentQuestionIndex = storage.currentQuestion;
		}
	},

	clearLocalStorage() {
		localStorage.removeItem(ANIMAL_TYPE_KEY);
		localStorage.removeItem(ANSWERS_KEY);
		localStorage.removeItem(CURRENT_QUESTION_KEY);
	}
});

export default mutations;
