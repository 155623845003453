import { isString } from 'lodash';

import { ValidationFunction } from './types';

export const rulesArrayToSingleRule = (rules: ValidationFunction[]): ValidationFunction => async (
	...args
) => {
	for (const rule of rules) {
		const result = await rule(...args);

		if (result !== true) {
			return result;
		}
	}

	return true;
};

export function changeErrorMessage<T>(
	cb: ValidationFunction<T>,
	newMessage: string
): ValidationFunction<T> {
	return (...args) => {
		const retVal = cb(...args);

		return isString(retVal) || !retVal ? newMessage : true;
	};
}
