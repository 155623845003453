<template>
	<router-link to="/" class="back">
		<div class="back__ico-wrapper">
			<img src="@/assets/images/global/ico-home.png" class="ico ico--init" alt="Home icon" />

			<img src="@/assets/images/global/ico-home-hover.png" class="ico ico--hover" alt="Home icon" />
		</div>

		<div class="back__text">
			WRÓĆ NA POCZĄTEK
		</div>
	</router-link>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss">
.back {
	@include flex-center-start;
	@include font-din-pro;

	position: fixed;
	top: 5.5rem;
	left: 5.5rem;
	z-index: $z_index_over_content;
	color: white;
	font-size: 2rem;
	font-weight: bold;
	line-height: (26 / 20);
	text-decoration: none;
	transition: $transition_default;
	transition-property: color;

	@include max-laptop {
		top: 48px;
		left: 33px;
		font-size: 20px;
		line-height: (26 / 20);
	}

	@include bp(1200) {
		top: 22px;
		left: 24px;
	}

	.back__ico-wrapper {
		@include inline-flex-center;

		width: 5rem;
		height: 5rem;
		margin-right: 11px;
		position: relative;

		@include max-laptop {
			width: 47px;
			height: 47px;
			margin-right: 9px;
		}

		@include max-phablet {
			width: 32px;
			height: 34px;
			margin: 0;
		}

		.ico {
			max-width: 90%;
			max-height: 90%;
			position: absolute;
			top: 50%;
			left: 50%;
			transition: $transition_default;
			transition-property: opacity;
			transform: translate(-50%, -50%);
		}
	}

	.back__text {
		padding-top: 0.3em;
		font-size: 20px;

		@include max-phablet {
			display: none;
		}
	}

	.ico--hover {
		opacity: 0;
	}

	&:hover {
		@include min-tablet {
			color: $color_cyan_dark_2;

			.ico--init {
				opacity: 0;
			}

			.ico--hover {
				opacity: 1;
			}
		}
	}
}
</style>
