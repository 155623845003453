import { computed } from '@vue/runtime-core';
import { omit } from 'lodash';

import { useVModelProxy } from '@/composables/helpers/useVModelProxy';
import { EmitFn } from '@/tools/types';

export function useFormFieldWrapper<P extends { modelValue: any }>(
	props: P,
	emit: EmitFn<['update:modelValue']>
) {
	const filteredProps = computed(() => omit(props, ['modelValue']));

	return {
		model: useVModelProxy(props, emit),
		filteredProps
	};
}
