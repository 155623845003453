<template>
	<div class="text-accordion" :class="accordionClasses">
		<div
			v-if="hasContent"
			:id="contentID"
			ref="contentRef"
			:aria-labelledby="triggerID"
			class="text-accordion__content"
		>
			<slot />
		</div>

		<button
			class="text-accordion__trigger"
			type="button"
			v-bind="triggerAttributes"
			@click="onToggle"
		>
			{{ triggerText }}
		</button>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';

import { VueClassBinding } from '@/tools/types';

import { useAccordionCore } from './base/accordion/composable';

export default defineComponent({
	props: {
		expanded: {
			type: Boolean
		},

		disabled: {
			type: Boolean
		}
	},

	emits: ['opened', 'closed', 'update:expanded'],

	setup(props, { slots, emit }) {
		const { isExpanded, ...core } = useAccordionCore(props, slots, emit, { minHeight: '1em' });

		watch(isExpanded, () => {
			emit(isExpanded.value ? 'opened' : 'closed');
		});

		return {
			...core,

			triggerText: computed(() => {
				return isExpanded.value ? 'mniej' : 'więcej';
			}),

			accordionClasses: computed(
				(): VueClassBinding => ({ 'text-accordion--is-expanded': isExpanded.value })
			)
		};
	}
});
</script>

<style lang="scss">
.text-accordion {
	$self: &;

	width: 100%;
	position: relative;

	&__content {
		color: $color_brown;
		overflow: hidden;
	}

	&__trigger {
		margin: 0;
		padding: 0;
		font-size: inherit;
		line-height: inherit;
		text-decoration: underline;
		border: none;
	}
}
</style>
