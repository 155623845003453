import { defineGetters, getGetters } from '@owlsdepartment/vuex-typed';
import { last } from 'lodash';

import { CatQuestions } from '@/constants/questions/CatQuestions';
import { DogQuestions } from '@/constants/questions/DogQuestions';
import { AnswerModel } from '@/models/AnswerModel';
import { QuestionModel } from '@/models/QuestionModel';

import state from './state';

const getters = defineGetters(state, {
	currentQuestion: (state): QuestionModel | undefined => {
		return questionsGetters.currentRoute()[state.currentQuestionIndex];
	},

	questions: state => {
		if (!state.type) {
			return null;
		}

		return [...(state.type === 'cat' ? CatQuestions : DogQuestions)];
	},

	questionsMap: (): Record<number, QuestionModel> | null => {
		return (
			questionsGetters.questions()?.reduce((acc, q) => {
				acc[q.id] = q;

				return acc;
			}, {} as Record<number, QuestionModel>) ?? null
		);
	},

	currentRoute: (state): QuestionModel[] => {
		if (!state.type) {
			return [];
		}

		const questions = questionsGetters.questions();
		const questionsMap = questionsGetters.questionsMap();

		if (!questions || !questionsMap) {
			return [];
		}

		if (state.answers.length === 0) {
			return [questions[0]];
		}

		const route: QuestionModel[] = [questionsMap[state.answers[0].id]];

		let createRoute = true;
		while (createRoute) {
			const nextId = getNextQuestionId(state.answers, route);

			if (nextId === null) {
				createRoute = false;
			} else {
				const nextQuestion = questionsMap[nextId];

				route.push(nextQuestion);
			}
		}

		return route;
	},

	answersMap: state => {
		return state.answers.reduce((acc, answer) => {
			acc[answer.id] = answer;

			return acc;
		}, {} as Record<number, AnswerModel>);
	}
});

function getNextQuestionId(answers: AnswerModel[], route: QuestionModel[]): number | null {
	const routeLast = last(route);
	const answer = answers.find(a => a.id === routeLast?.id);

	if (!routeLast || routeLast.type === 'email' || !answer) {
		return null;
	}

	let nextQuestion: number;

	if (routeLast.type === 'date') {
		if (routeLast.data.optional && answer.answer === true) {
			nextQuestion = routeLast.data.nextOptionalQuestion ?? routeLast.data.nextQuestion;
		} else {
			nextQuestion = routeLast.data.nextQuestion;
		}
	} else {
		nextQuestion = routeLast.data[answer.answer ? 'left' : 'right'].nextQuestion;
	}

	return nextQuestion;
}

export const questionsGetters = getGetters(getters, 'questions');

export default getters;
