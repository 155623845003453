
import { defineComponent, PropType } from 'vue';

import { useFormFieldWrapper } from '@/common/validation/composables';
import { ValidationRule } from '@/common/validation/helpers';
import { RadioOption, RadioValue } from '@/components/base/radio/BaseRadio.vue';
import { useHasSlot } from '@/composables';

export default defineComponent({
	inheritAttrs: false,

	props: {
		/**
		 * Native input `name`
		 */
		name: {
			type: String,
			default: ''
		},

		rules: {
			type: [String, Function, Array, Object] as PropType<ValidationRule>,
			default: () => []
		},
		/**
		 * Optional fieldset `legend`
		 */
		legend: {
			type: String,
			default: ''
		},
		/**
		 * All radio options, rendered as inputs
		 */
		options: {
			type: Array as PropType<RadioOption[]>,
			default: () => [] as RadioOption[]
		},
		/**
		 * Display radio inputs in two columns
		 * Maybe change to `columns: number;`?
		 */
		columns: {
			type: Number,
			default: 1
		},

		modelValue: {
			type: [Number, String, Boolean] as PropType<RadioValue>,
			default: ''
		}
	},

	emit: ['update:modelValue'],

	setup(props, { emit, slots }) {
		return {
			hasLegend: useHasSlot('legend', slots),

			...useFormFieldWrapper(props, emit)
		};
	}
});
