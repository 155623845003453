
import { defineComponent, ref } from 'vue';

export default defineComponent({
	name: 'Dropdown',
	props: {
		selected: {
			type: [Number, String],
			required: true
		},
		values: {
			type: Array,
			required: true
		},
		transparent: {
			type: Boolean
		}
	},
	emits: ['update'],
	setup() {
		const dropdownValues_0 = ref<HTMLElement | null>(null);

		function onKeydownLabel() {
			if (dropdownValues_0.value) {
				dropdownValues_0.value.focus();
			}
		}

		function onKeydownInside(event: any) {
			event.preventDefault();

			if (event.code === 'ArrowDown') {
				if (event?.target?.nextElementSibling) {
					event.target.nextElementSibling.focus();
				}
			} else {
				if (event?.target?.previousElementSibling) {
					event.target.previousElementSibling.focus();
				}
			}
		}

		return {
			onKeydownLabel,
			onKeydownInside,
			dropdownValues_0
		};
	},
	data() {
		return {
			isOpened: false,
			currentValue: this.selected
		};
	},
	methods: {
		toggleDropdown() {
			this.isOpened = !this.isOpened;
		},
		changeValue(value: any) {
			this.currentValue = value;
			this.$emit('update', this.currentValue);
			this.isOpened = false;
		},
		onClickOutside() {
			this.isOpened = false;
		}
	}
});
