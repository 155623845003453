<template>
	<p class="error-message">
		<slot />
	</p>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>

<style lang="scss">
.error-message {
	color: $color_error;
	font-size: 9px;
	line-height: 1.2;
}
</style>
