import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import LayoutQuestion from '@/layouts/LayoutQuestion.vue';

const ViewQuestions = () =>
	import(/* webpackChunkName: "ViewQuestions" */ '@/views/ViewQuestions.vue');
const ViewCatQuestions = () =>
	import(/* webpackChunkName: "CatQuestions" */ '@/views/ViewCatQuestions.vue');
const ViewDogQuestions = () =>
	import(/* webpackChunkName: "DogQuestions" */ '@/views/ViewDogQuestions.vue');

export default createRouteNode({
	shared: {
		basePath: 'pytania',
		meta: {
			layout: {
				component: LayoutQuestion
			}
		}
	},

	routes: [
		{
			path: '',
			name: 'questions',
			component: ViewQuestions,
			meta: {
				layout: {
					component: LayoutQuestion,
					props: {
						showBack: false
					}
				}
			}
		},
		{
			path: 'kot',
			name: 'catQuestions',
			component: ViewCatQuestions
		},
		{
			path: 'pies',
			name: 'dogQuestions',
			component: ViewDogQuestions
		}
	]
});
