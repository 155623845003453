<template>
	<div class="base-accordion" :class="accordionClasses">
		<component
			:is="triggerTag"
			class="base-accordion__trigger"
			v-bind="triggerAttributes"
			@click="onToggle"
		>
			<div class="base-accordion__trigger-title">
				<slot name="title" />
			</div>
		</component>

		<div
			v-if="hasContent"
			:id="contentID"
			ref="contentRef"
			:aria-labelledby="triggerID"
			class="base-accordion__content"
		>
			<slot />
		</div>

		<slot name="footer" />
	</div>
</template>

<script lang="ts">
import { computed, defineComponent, watch } from 'vue';

import { VueClassBinding } from '@/tools/types';

import { useAccordionCore } from './composable';

export default defineComponent({
	props: {
		expanded: {
			type: Boolean
		},

		disabled: {
			type: Boolean
		}
	},

	emits: ['opened', 'closed', 'update:expanded'],

	setup(props, { slots, emit }) {
		const { isExpanded, ...core } = useAccordionCore(props, slots, emit);

		watch(isExpanded, () => {
			emit(isExpanded.value ? 'opened' : 'closed');
		});

		return {
			...core,

			accordionClasses: computed(
				(): VueClassBinding => ({ 'base-accordion--is-expanded': isExpanded.value })
			)
		};
	}
});
</script>

<style lang="scss">
.base-accordion {
	$self: &;

	width: 100%;
	position: relative;
}
</style>
