
import { useField, useFieldError } from 'vee-validate';
import { computed, defineComponent, provide, ref } from 'vue';

import { errorDisplayKey } from '@/common/validation';
import { useHasSlot } from '@/composables';

export default defineComponent({
	props: {
		for: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: ''
		},

		name: {
			type: String,
			default: ''
		}
	},

	setup(props, { slots }) {
		const errorDisplay = ref<string>('');
		const fieldError = props.name ? useFieldError(props.name) : undefined;
		const fieldMeta = props.name ? useField(props.name) : undefined;

		provide(errorDisplayKey, errorDisplay);

		return {
			showLabel: useHasSlot('label', slots, hasSlot => hasSlot || !!props.label),
			error: computed(() => fieldError?.value ?? errorDisplay.value),
			meta: computed(() => fieldMeta?.value)
		};
	}
});
