import axios from 'axios';
import { Store } from 'vuex';

import { $isLoading } from '@/common/loading';

const REFRESH_TOKEN_ACTION = 'auth/refreshToken';
const PASSIVE_LOGOUT = 'auth/passiveLogout';
// const IS_TOKEN_VALID = 'auth/isTokenValid';

let tokenRefreshing = Promise.resolve();
const isRefreshTokenLoading = (): boolean => $isLoading(REFRESH_TOKEN_ACTION);

export function applyOAuth(store: Store<any>): void {
	axios.interceptors.request.use(
		async config => {
			const { auth } = store.state;
			const isTokenRequired = false;
			const isTokenValid = true;

			if (!isTokenRequired) return config;
			if (!isTokenValid) {
				try {
					await tryRefreshToken(store);
				} catch {
					return config;
				}
			}

			config.headers.Authorization = `${auth.tokenType} ${auth.accessToken}`;

			return config;
		},
		error => Promise.reject(error)
	);

	axios.interceptors.response.use(
		response => response,
		error => {
			if (error.response && error.response.status === 401) {
				store.dispatch(PASSIVE_LOGOUT);
			}

			return Promise.reject(error);
		}
	);
}

async function tryRefreshToken(store: Store<any>) {
	if (isRefreshTokenLoading()) {
		return await tokenRefreshing;
	}

	// eslint-disable-next-line @typescript-eslint/no-empty-function
	let resolveRefreshing = () => {};

	tokenRefreshing = new Promise(resolve => (resolveRefreshing = resolve));

	try {
		await store.dispatch(REFRESH_TOKEN_ACTION);
	} catch (error) {
		if (error.response) {
			store.dispatch(PASSIVE_LOGOUT);

			throw error;
		}
	} finally {
		resolveRefreshing();
	}
}
