
import { defineComponent } from 'vue';

import BaseButton from '@/components/base/button/BaseButton.vue';
import { useGetImage } from '@/composables';

export default defineComponent({
	components: {
		BaseButton
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			images: {
				headline: getHomeImage('headline'),
				// Cat for some reason needs to be like this
				catMobile: require(`@/assets/images/view-home/cat-mobile.png`),
				dogMobile: getHomeImage('dog-mobile'),
				packshotsMobile: getHomeImage('packshots-mobile'),
				logoDrontal: getHomeImage('logo-drontal'),
				logoDronspot: getHomeImage('logo-dronspot'),
				logoVeto: getHomeImage('logo-veto')
			}
		};
	}
});
