import { Class } from '../types';

type SimpleMap<T> = { [Key in keyof T]: T[Key] };

export function createStoreClassInstance<T>(Target: Class<T>): SimpleMap<T> {
	const NewClass = StoreClass(Target);

	return new NewClass();
}

export function StoreClass<T extends Class<any>, Instance extends InstanceType<T>>(
	Target: T
): new (...args: any[]) => SimpleMap<Instance> {
	return class extends Target {
		constructor(...args: any[]) {
			super(...args);

			applyMethods(this, Target);
		}
	};
}

function applyMethods<T extends Class<any>>(base: any, Target: T) {
	if (!Target.prototype) {
		return;
	}

	const methods = Object.getOwnPropertyNames(Target.prototype);

	for (const method of methods) {
		if (method === 'constructor') continue;

		Object.defineProperty(base, method, {
			...Object.getOwnPropertyDescriptor(Target.prototype, method),
			enumerable: true
		});
	}

	applyMethods(base, Object.getPrototypeOf(Target));
}
