
import { computed, defineComponent, watch } from 'vue';

import { VueClassBinding } from '@/tools/types';

import { useAccordionCore } from './composable';

export default defineComponent({
	props: {
		expanded: {
			type: Boolean
		},

		disabled: {
			type: Boolean
		}
	},

	emits: ['opened', 'closed', 'update:expanded'],

	setup(props, { slots, emit }) {
		const { isExpanded, ...core } = useAccordionCore(props, slots, emit);

		watch(isExpanded, () => {
			emit(isExpanded.value ? 'opened' : 'closed');
		});

		return {
			...core,

			accordionClasses: computed(
				(): VueClassBinding => ({ 'base-accordion--is-expanded': isExpanded.value })
			)
		};
	}
});
