import { inject, InjectionKey, provide } from '@vue/runtime-core';
import { Ref, shallowRef } from 'vue';

import { FormContext } from './types';

/**
 * Provide and inject FormContext from Form to it's children
 */
const formContextKey: InjectionKey<FormContext> = Symbol();

export const useProvideFormContext = (context: FormContext) => {
	provide(formContextKey, context);
};

export function useInjectFormContext(): FormContext;
export function useInjectFormContext(canThrow: true): FormContext | null;
export function useInjectFormContext(canThrow = false) {
	const controls = inject(formContextKey, null);

	if (!controls && !canThrow) {
		throw Error("[useFormControls] Form controls wasn't provided!");
	}

	return controls;
}

/**
 * Provide and inject register function from parent component to Form and then register FormContext in parent component
 */
export const registerFormContextKey: InjectionKey<(
	controls: FormContext,
	name?: string
) => void> = Symbol('register-form-controls');

/**
 * Returned FormContext won't be available until `mounted` hook and still can be `undefined`, when no form will register
 */
export function useFormContext(name?: string): Ref<FormContext | undefined> {
	const formControls = shallowRef<FormContext | undefined>();

	provide(registerFormContextKey, (controls, formName = '') => {
		if (formControls.value) {
			return;
		}

		if (!name || name === formName) {
			formControls.value = controls;
		}
	});

	return formControls;
}

export function useRegisterFormContext(context: FormContext, name?: string): void {
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	const register = inject(registerFormContextKey, () => {});

	register(context, name);
}
