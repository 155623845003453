import { computed, inject, Ref, watch } from '@vue/runtime-core';
import { useField as useVeeField } from 'vee-validate';
import { InjectionKey } from 'vue';

import { rulesArrayToSingleRule, ValidationRule } from '../helpers';
import { useRegisterField } from './useFieldContext';

export interface UseValidationProps {
	name: string;
	rules: ValidationRule;
	validateOnMount: boolean;
}

export const errorDisplayKey: InjectionKey<Ref<string>> = Symbol();

const useErrorDisaply = (errors: Ref<string[]>) => {
	const errorDisplay = inject(errorDisplayKey, undefined);

	watch(errors, () => {
		if (errorDisplay) {
			errorDisplay.value = errors.value[0] ?? '';
		}
	});
};

export function useField(props: UseValidationProps) {
	// vee-validate adds `type` config option if input is `radio` or `checkbox` or other, for
	// `checked` value, but we don't need it and assume component will handle `checked` for us
	const rules = computed<ValidationRule>(() => {
		if (Array.isArray(props.rules)) {
			return rulesArrayToSingleRule(props.rules);
		}

		return props.rules;
	});
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const field = useVeeField(props.name, rules, {
		validateOnMount: props.validateOnMount,
		validateOnValueUpdate: false
	});

	useRegisterField(field);
	useErrorDisaply(field.errors);

	return { ...field };
}
