
import { defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';

import { LayoutSystem } from '@/common/layout-system';
import defaultLayout from '@/layouts/LayoutDefault.vue';
import { appViewportHeight } from '@/mixins/app';

import { questionsMutations } from './store/modules/questions';

export default defineComponent({
	components: {
		LayoutSystem
	},

	mixins: [appViewportHeight],

	setup() {
		const $route = useRoute();
		const questionnaireRoutes = ['dogQuestions', 'catQuestions'];

		const destroyInitStorageWatcher = watch(
			() => $route.name,
			name => {
				if (!questionnaireRoutes.includes(`${name?.toString()}`)) {
					questionsMutations.clearLocalStorage();
				}

				destroyInitStorageWatcher();
			}
		);

		return {
			defaultLayout
		};
	},
	mounted() {
		// turn off ios bounce effect
		//const preventDefault = (e: any) => {
		//	e.preventDefault();
		//};
		//document.body.addEventListener('touchmove', preventDefault, { passive: false });
	}
});
