<template>
	<layout-system v-bind="{ defaultLayout }">
		<router-view />
	</layout-system>
</template>

<script lang="ts">
import { defineComponent, watch } from 'vue';
import { useRoute } from 'vue-router';

import { LayoutSystem } from '@/common/layout-system';
import defaultLayout from '@/layouts/LayoutDefault.vue';
import { appViewportHeight } from '@/mixins/app';

import { questionsMutations } from './store/modules/questions';

export default defineComponent({
	components: {
		LayoutSystem
	},

	mixins: [appViewportHeight],

	setup() {
		const $route = useRoute();
		const questionnaireRoutes = ['dogQuestions', 'catQuestions'];

		const destroyInitStorageWatcher = watch(
			() => $route.name,
			name => {
				if (!questionnaireRoutes.includes(`${name?.toString()}`)) {
					questionsMutations.clearLocalStorage();
				}

				destroyInitStorageWatcher();
			}
		);

		return {
			defaultLayout
		};
	},
	mounted() {
		// turn off ios bounce effect
		//const preventDefault = (e: any) => {
		//	e.preventDefault();
		//};
		//document.body.addEventListener('touchmove', preventDefault, { passive: false });
	}
});
</script>

<style lang="scss">
html {
	width: 100%;
	height: 100%;
	color: $color_cyan;
	font-size: 11px;
	overflow-y: scroll;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	@media screen and (max-width: 2000px) {
		font-size: 10px;
	}

	@media screen and (max-width: 1800px), screen and (max-height: 1000px) {
		font-size: 9px;
	}

	@media screen and (max-width: 1400px) {
		font-size: 8.5px;
	}

	@media screen and (max-width: 1024px) {
		font-size: 8px;
	}
}

body {
	@include font-roboto;

	position: relative;
	font-size: 1.6rem;
	line-height: (18.75 / 16);
	overflow: hidden;

	&::before {
		@include main-bg;

		content: '';
		width: 100%;
		height: 100vh; // without vh fix
		position: fixed;
		left: 0;
	}
}

a,
button,
label {
	user-select: none;
	background: transparent;
	-webkit-tap-highlight-color: rgb(255 255 255 / 0%);

	&:focus {
		outline: thin dotted;
	}

	&:hover,
	&:active {
		outline: 0;
	}

	&:active,
	&:focus {
		outline: 0;
		background: transparent;
	}
}
</style>
