import { defineModule } from '@owlsdepartment/vuex-typed';

import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const module = defineModule({
	state,
	getters,
	mutations,
	actions
});

export default module;
