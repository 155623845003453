import { defineComponent } from 'vue';

function updateVHVar() {
	const vh = window.innerHeight * 0.01;

	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export const appViewportHeight = defineComponent({
	mounted() {
		updateVHVar();

		window.addEventListener('resize', updateVHVar);
	},

	unmounted() {
		window.removeEventListener('resize', updateVHVar);
	}
});
