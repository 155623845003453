import '@/styles/main.scss';
import '@/tools/rules/globalRules';

import vClickOutside from 'click-outside-vue3';
import VLazyImage from 'v-lazy-image';
import { createApp } from 'vue';

import { loadingStatePlugin } from '@/common/loading';
import * as baseComponents from '@/components/base';
import * as formComponents from '@/components/form';
import * as globalComponents from '@/components/global';
import { inviewDirective } from '@/directives/inview';
import { globalComponentsPlugin } from '@/plugins';

import App from './App.vue';
import router from './router';
import store from './store';

createApp(App)
	.use(store)
	.use(router)
	.use(vClickOutside)
	.use(loadingStatePlugin)
	.directive('inview', inviewDirective)
	.use(globalComponentsPlugin([baseComponents, globalComponents, formComponents]))
	.component('VLazyImage', VLazyImage)
	.mount('#app');
