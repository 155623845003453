
import { Component, defineComponent, Prop, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import { useHasSlot } from '@/composables';
import { enumValidator } from '@/tools/helpers';
import { AnyObject } from '@/tools/types';

import { ButtonSize, ButtonTag, ButtonType } from './types';

/**
 * If value is empty string, than class modificator will be of this prop's value, f. ex.:
 * { size: '' }
 *
 * class -> `base-button--${this.size}`
 */
const classToParams = {
	size: '',
	loading: 'is-loading',
	disabled: 'is-disabled',
	primary: 'primary',
	secondary: 'secondary',
	small: 'small',
	minimal: 'minimal',
	inline: 'inline',
	iconOnly: 'icon-only',
	uppercase: 'uppercase'
};

const getClassProps = () => {
	type ParamsKeys = keyof typeof classToParams;
	const props: Record<ParamsKeys, Prop<boolean>> = {} as any;

	for (const key of Object.keys(classToParams)) {
		props[key as ParamsKeys] = {
			type: Boolean
		};
	}

	return props;
};

export default defineComponent({
	props: {
		tag: {
			type: [String, Object] as PropType<ButtonTag>,
			default: 'button'
		},

		to: {
			type: [String, Object] as PropType<RouteLocationRaw>,
			default: null
		},

		type: {
			type: String as PropType<ButtonType>,
			default: 'button' as const,
			validator: enumValidator<ButtonType>(['button', 'submit', 'reset'])
		},

		...getClassProps(),

		size: {
			type: String as PropType<ButtonSize>,
			default: null
		}
	},

	setup(props, { slots }) {
		const hasIcon = useHasSlot('icon', slots);
		const hasContent = useHasSlot('default', slots);

		return {
			hasIcon,
			hasContent
		};
	},

	computed: {
		buttonTag(): string | Component {
			return this.to ? 'router-link' : this.tag;
		},

		buttonType(): ButtonType | false {
			return this.tag === 'button' ? this.type : false;
		},

		optionalAttributes(): AnyObject {
			const additional: AnyObject = {};

			if (this.type === 'button') {
				additional.disabled = this.disabled;
			}

			return additional;
		},

		additionalClasses(): Array<string> {
			const classes: string[] = [];

			for (const _param in classToParams) {
				const param = _param as keyof typeof classToParams;

				if (this[param]) {
					let className = classToParams[param];

					if (!className) {
						className = `${this[param]}`;
					}

					classes.push(`base-button--${className}`);
				}
			}

			return classes;
		}
	}
});
