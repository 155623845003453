<template>
	<component :is="tag" class="base-checkbox">
		<legend v-if="hasLegend" class="base-checkbox__legend">
			<slot name="legend">
				{{ legend }}
			</slot>
		</legend>

		<label
			v-for="({ value }, index) in checkboxOptions"
			:key="index"
			class="base-checkbox__content"
			:class="{
				'base-checkbox__content--is-checked': isChecked(index),
				'base-checkbox__content--border': border,
				'base-checkbox__content--bigger': bigger
			}"
		>
			<input
				v-model="model"
				class="base-checkbox__input visually-hidden"
				type="checkbox"
				:checked="isChecked(index)"
				v-bind="{ name, value, ...inputOptionalAttributes(index) }"
				@change.stop
				@input.stop
			/>

			<p class="base-checkbox__label">
				<text-accordion>
					<slot name="label" v-bind="{ label: getLabel(index), index }">
						{{ getLabel(index) }}
					</slot>
				</text-accordion>
			</p>
		</label>
	</component>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import TextAccordion from '@/components/TextAccordion.vue';
import { useHasSlot, useVModelProxy } from '@/composables';
import { AnyObject } from '@/tools/types';

export type CheckboxValue = boolean | number | string;

export interface CheckboxOption {
	label?: string;
	value: CheckboxValue;
	slug?: string;
	disabled?: boolean;
}

export default defineComponent({
	components: {
		TextAccordion
	},

	props: {
		/**
		 * Native input `name`
		 */
		name: {
			type: String,
			default: ''
		},
		/**
		 * Optional fieldset `legend`
		 */
		legend: {
			type: String,
			default: ''
		},
		/**
		 * v-model prop
		 */
		modelValue: {
			type: [Array, Boolean] as PropType<CheckboxValue[] | boolean>,
			default: () => []
		},
		/**
		 * All checkbox options, rendered as inputs
		 */
		options: {
			type: Array as PropType<CheckboxOption[]>,
			default: () => [] as CheckboxOption[]
		},
		/**
		 * Display checkbox inputs in two columns
		 * Maybe change to `columns: number;`?
		 */
		columns: {
			type: Number,
			default: 1,
			validator: (val: number) => val >= 1
		},

		bigger: Boolean,

		border: Boolean,

		single: Boolean,

		hasError: Boolean
	},

	emits: ['update:modelValue'],

	setup(props, { emit, slots }) {
		const model = useVModelProxy(props, emit);
		if (props.single && Array.isArray(props.modelValue)) {
			model.value = false;
		}

		return {
			model,
			getLabel: computed(() => (index: number) => {
				const option = props.options[index];

				return option?.label ?? option?.value ?? '';
			}),
			hasLegend: useHasSlot('legend', slots, slot => slot || !!props.legend)
		};
	},

	computed: {
		checkboxOptions(): CheckboxOption[] {
			return this.single ? [this.options[0]] : this.options;
		},

		isChecked(): (i: number) => boolean {
			return index => {
				if (Array.isArray(this.model)) {
					return this.model.includes(this.options[index].value);
				}

				return this.model;
			};
		},

		isAnySelected(): boolean {
			return Array.isArray(this.model) ? this.model.length !== 0 : this.model;
		},

		inputOptionalAttributes(): (index: number) => AnyObject {
			return index => {
				const attrs: AnyObject = {};
				const { slug, disabled } = this.options[index];

				if (slug) {
					attrs['id'] = slug;
				}

				if (disabled) {
					attrs['disabled'] = disabled;
				}

				return attrs;
			};
		},

		tag(): string {
			return this.hasLegend ? 'fieldset' : 'div';
		}
	}
});
</script>

<style lang="scss">
.base-checkbox {
	$self: &;

	&__content {
		@include no-select;

		display: block;
		position: relative;

		&::before,
		&::after {
			content: '';
			width: 16px;
			height: 16px;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 3px;

			@include max-phablet {
				width: 24px;
				height: 24px;
			}
		}

		&::before {
			transition: background-color 230ms ease;
			border: 1px solid #000;
			background-color: transparent;
		}

		&::after {
			z-index: 2;
		}

		&:hover {
			@include min-tablet {
				&::before {
					background-color: $color_green;
				}

				.final-result__col & {
					&::before {
						background-color: darken($color_cyan, 10%);
					}
				}
			}
		}
	}

	#{$self}__content--is-checked {
		&::after {
			$checked_bg: url('~@/assets/images/global/checked.svg');

			background: transparent $checked_bg no-repeat center center / 12px;
		}
	}

	&__label {
		display: block;
		padding: 0 0 0 16px + 7.6;
		font-size: 9px;
		line-height: (10.55 / 9);
		text-align: justify;

		@include max-phablet {
			padding-left: 24px + 16;
		}

		@include max-mobile {
			line-height: (10.55 / 9);
		}
	}

	#{$self}__content--bigger {
		&::before,
		&::after {
			content: '';
			width: 26px;
			height: 26px;
		}

		&::after {
			background-size: 16px;
		}

		#{$self}__label {
			padding: 0.3em 0 0 31px + 7.6;
			font-size: 1.6rem;
			line-height: (19 / 16);

			@include tablet-portrait {
				font-size: 16px;
			}

			@include max-phablet {
				padding-top: 0;
			}
		}
	}

	#{$self}__content--border {
		&::before {
			border: 1px solid $color_gray_light;
		}
	}

	&--has-error {
		#{$self}__box {
			border-color: red;
		}
	}

	+ .error-message {
		padding-left: 16px + 7.6;

		@include max-phablet {
			padding-left: 24px + 16;
		}
	}
}
</style>
