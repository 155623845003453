import { pick } from 'lodash';
import { useForm as useVeeForm } from 'vee-validate';
import { ComputedRef, reactive, watch } from 'vue';

import { AnyObject, EmitFn } from '@/tools/types';

import { ValidationSchema } from '../helpers';
import { FormContext } from './types';
import { useFieldContextSystem } from './useFieldContext';
import { useProvideFormContext, useRegisterFormContext } from './useFormContext';

export interface FormProps {
	initial: AnyObject;
	validationSchema?: ValidationSchema;
	validateOnMount: boolean;
	errors: AnyObject;
	registerName?: string;
}

export function useForm(props: FormProps, emit: EmitFn<['update:errors', 'submit', 'reset']>) {
	const { resetForm: _resetForm, ...controls } = useVeeForm({
		initialValues: props.initial,
		validationSchema: props.validationSchema,
		validateOnMount: props.validateOnMount
	});
	const { getFieldContext } = useFieldContextSystem();

	const submitForm = controls.handleSubmit((values, actions) => {
		emit('submit', values, actions);
	});
	const resetForm = () => {
		_resetForm({ values: props.initial });
		emit('reset');
	};
	const context: FormContext = {
		...controls,
		resetForm,
		submitForm,
		getFieldContext
	};

	useProvideFormContext({ ...context });
	useRegisterFormContext({ ...context }, props.registerName);

	useErrorsControls(props, {
		emit,
		errors: controls.errors,
		setErrors: controls.setErrors
	});

	return {
		context,

		forSlot: reactive({
			...pick(controls, ['isSubmitting', 'meta', 'errors', 'setValues', 'setFieldValue']),

			submitForm,
			resetForm
		})
	};
}

function useErrorsControls(
	props: { errors: AnyObject },
	{
		emit,
		setErrors,
		errors
	}: {
		emit: EmitFn<'update:errors'[]>;
		setErrors: (fields: AnyObject) => void;
		errors: ComputedRef<AnyObject>;
	}
) {
	let changedErrorsFromProps = false;

	watch(
		() => props.errors,
		err => {
			if (err) {
				changedErrorsFromProps = true;
				setErrors(err);
			}
		},
		{ deep: true }
	);

	watch(errors, () => {
		if (changedErrorsFromProps) {
			changedErrorsFromProps = false;

			return;
		}

		emit('update:errors', errors.value);
	});
}
