import dayjs from 'dayjs';

import { AnswerColumn } from '@/models/AnswerModel';
import { QuestionModel } from '@/models/QuestionModel';
import {
	addSingleUnit,
	assureFutureDate,
	defaultQuestionHeadline,
	isBeforeMonthFromNowOnOptionalUnit,
	isBeforeMonthFromNowOnUnit,
	isBeforeMonthsFromNowOnOptionalUnit
} from '@/tools/helpers/finalDateHelpers';

export const CatQuestions: QuestionModel[] = [
	{
		id: 1,
		type: 'boolean',
		title: 'W jakim wieku jest Twój kot?',
		data: {
			left: {
				label: 'Dorosły',
				subTitle: 'Powyżej 6 miesięcy',
				nextQuestion: 4
			},
			right: {
				label: 'Kocię',
				subTitle: 'Od 3. tygodnia do 6. miesiąca życia, koty ważące ponad 0,5 kg',
				nextQuestion: 3
			}
		}
	},
	{
		id: 3,
		type: 'email',
		title: 'Zalecenia dla Kociaka',
		data: {
			recommendations:
				'Od 3. tygodnia życia, następnie <strong>co 2 tygodnie</strong> do momentu odsadzenia.',
			additionalNotes:
				'Twój pupil jest teraz w szczególnym okresie swojego życia i wymaga opieki lekarza weterynarii, który to doradzi Ci dobór substancji, dawkę i częstotliwość podania leku.',
			specialDisclaimer: true
		}
	},
	{
		id: 4,
		type: 'boolean',
		title: 'Jakiej płci jest Twój kot?',
		data: {
			left: {
				label: 'Samica',
				nextQuestion: 5
			},
			right: {
				label: 'Samiec',
				nextQuestion: 7
			}
		}
	},
	{
		id: 5,
		type: 'boolean',
		title: 'Czy kotka jest karmiąca?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 7
			},
			right: {
				label: 'Tak',
				nextQuestion: 12
			}
		}
	},
	{
		id: 6,
		type: 'date',
		title: 'Twój kot ostatnio odrobaczany był...',
		data: {
			optional: false,
			nextQuestion: 14 // zwieksz ryzyko
		}
	},
	{
		id: 7,
		type: 'boolean',
		title: 'Czy przebywa w warunkach zwiększonego ryzyka: wystawy?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 8
			},
			right: {
				label: 'Tak',
				nextQuestion: 11
			}
		}
	},
	{
		id: 8,
		type: 'boolean',
		title: 'Czy przebywa w warunkach zwiększonego ryzyka: hodowle?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 9
			},
			right: {
				label: 'Tak',
				nextQuestion: 6
			}
		}
	},
	{
		id: 9,
		type: 'boolean',
		title: 'Czy przebywa w domu z małymi dziećmi lub osobami z obniżoną odpornością?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 10
			},
			right: {
				label: 'Tak',
				nextQuestion: 20
			}
		}
	},
	{
		id: 10,
		type: 'boolean',
		title: 'Czy wychodzi z domu?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 19
			},
			right: {
				label: 'Tak',
				nextQuestion: 18
			}
		}
	},
	{
		id: 11,
		type: 'date',
		title: 'Kiedy jest najbliższa planowana wystawa?',
		data: {
			optional: false,
			nextQuestion: 13
		}
	},
	{
		id: 12,
		type: 'email',
		title: 'Zalecenia dla kotki karmiącej',
		data: {
			recommendations:
				'Aby zapobiec laktogennej transmisji <em>Toxocara cati</em>, zaleca się <strong>jednokrotne</strong> podanie preparatu podczasu okresu karmienia mlekiem jednocześnie z <strong>pierwszym odrobaczaniem</strong> kociąt.',
			additionalNotes:
				'Twój pupil jest teraz w szczególnym okresie swojego życia i wymaga opieki lekarza weterynarii, który to doradzi Ci dobór substancji, dawkę i częstotliwość podania leku.',
			calculateDates: () => {
				return [];
			}
		}
	},
	{
		id: 13,
		type: 'email',
		title: 'Zalecenia dla zwiększonego ryzyka',
		data: {
			recommendations:
				'W hodowlach leczenie <strong>co 4 tygodnie</strong> (jeśli nie są wykonywane regularnie badania kału).',
			calculateDates: answers => {
				const answer: any = answers[11]?.answer || '';
				const retVal: Array<AnswerColumn> = [];

				if (!answer) {
					return [];
				}

				const exhibitionDate = assureFutureDate(answer, 0);

				retVal.push({
					headline: 'Najbliższe <br/>przed wystawą',
					dates: [exhibitionDate.subtract(1, 'month')]
				});
				retVal.push({
					headline: 'Najbliższe <br/>po wystawie',
					dates: [exhibitionDate.add(2, 'week')]
				});

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthFromNowOnUnit(answers, 11);
			}
		}
	},
	{
		id: 14,
		type: 'email',
		title: 'Zalecenia dla zwiększonego ryzyka',
		data: {
			recommendations:
				'W hodowlach leczenie <strong>co 4 tygodnie</strong> (jeśli nie są wykonywane regularnie badania kału).',
			calculateDates: answers => {
				return addSingleUnit(answers, 6, 4, 'week');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthFromNowOnUnit(answers, 6);
			}
		}
	},
	{
		id: 15,
		type: 'email',
		title: 'Koty z dziećmi lub osobami z obniżoną odpornością',
		data: {
			recommendations:
				'W zależności od oceny ryzyka, badanie próbek kału <strong>raz w miesiącu</strong> (decyzja o leczeniu w zależności od wyników) lub planowe odrobaczanie <strong>raz w miesiacu</strong>.',
			calculateDates: answers => {
				const answer: any = answers[20]?.answer || '';
				const dateSelected = !(answer === true || answer === false);
				const willDewormIn: any = answers[23]?.answer ? 1 : 2;

				const retVal: Array<AnswerColumn> = [];

				if (dateSelected) {
					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [assureFutureDate(answer, 1, 'month')]
					});

					return retVal;
				} else {
					const today = dayjs();
					const willDewormInDate = today.add(willDewormIn, 'week');

					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [willDewormInDate, willDewormInDate.add(1, 'month')]
					});
				}

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthFromNowOnOptionalUnit(answers, 20);
			}
		}
	},
	{
		id: 16,
		type: 'email',
		title: 'Grupa ryzyka A',
		data: {
			recommendations:
				'Badanie kału <strong>1-2 razy do roku</strong> (decyzja o leczeniu w zależności od wyników) lub odrobaczanie <strong>1-2 razy do roku</strong> przeciwko nicieniom.',
			calculateDates: answers => {
				const answer: any = answers[19]?.answer || '';
				const dateSelected = !(answer === true || answer === false);
				const willDewormIn: any = answers[24]?.answer ? 1 : 2;
				const retVal: Array<AnswerColumn> = [];

				if (dateSelected) {
					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [assureFutureDate(answer, 6, 'month')]
					});

					return retVal;
				} else {
					const today = dayjs();
					const willDewormInDate = today.add(willDewormIn, 'week');

					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [willDewormInDate, willDewormInDate.add(6, 'month')]
					});
				}

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(6, answers, 19);
			}
		}
	},
	{
		id: 17,
		type: 'email',
		title: 'Grupa ryzyka B',
		data: {
			recommendations:
				'W celu zminimalizowania ryzyka wydalania jaj <em>Toxocara</em> i <em>Taenia</em>: badanie kału przynajmniej <strong>4 razy do roku</strong> (decyzja o leczeniu zależnie od wyników) lub odrobaczanie co najmniej <strong>4 razy do roku</strong> przeciwko nicieniom i tasiemcom.',
			calculateDates: answers => {
				const answer: any = answers[18]?.answer || '';
				const dateSelected = !(answer === true || answer === false);
				const willDewormIn: any = answers[25]?.answer ? 1 : 2;

				const retVal: Array<AnswerColumn> = [];

				if (dateSelected) {
					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [assureFutureDate(answer, 3, 'month')]
					});

					return retVal;
				} else {
					const today = dayjs();
					const willDewormInDate = today.add(willDewormIn, 'week');

					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [willDewormInDate, willDewormInDate.add(3, 'month')]
					});
				}

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(3, answers, 18);
			}
		}
	},
	{
		id: 18,
		type: 'date',
		title: 'Twój kot ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 17, // ryzyko B
			nextOptionalQuestion: 25
		}
	},
	{
		id: 19,
		type: 'date',
		title: 'Twój kot ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 16, // ryzyko A
			nextOptionalQuestion: 24
		}
	},
	{
		id: 20,
		type: 'date',
		title: 'Twój kot ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 15, // obnizona odpornosc
			nextOptionalQuestion: 23
		}
	},
	{
		id: 23,
		type: 'boolean',
		title: 'Odrobaczę kota w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 15 // obnizona odpornosc
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 15 // obnizona odpornosc
			}
		}
	},
	{
		id: 24,
		type: 'boolean',
		title: 'Odrobaczę kota w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 16 // ryzyko A
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 16 // ryzyko A
			}
		}
	},
	{
		id: 25,
		type: 'boolean',
		title: 'Odrobaczę kota w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 17 // ryzyko B
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 17 // ryzyko B
			}
		}
	}
];
