import { email as _email } from '@vee-validate/rules';

import { changeErrorMessage } from '@/common/validation';

export const email = changeErrorMessage(_email, 'Proszę podać poprawny adres email');

export { checked } from './checked';
export { confirmPassword } from './confirmPassword';
export { dateRequired } from './dateRequired';
export { noWhitespaceOnly } from './noWhitespaceOnly';
export { required } from './required';
