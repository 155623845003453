import { ActionContext } from '@owlsdepartment/vuex-typed';
import jsonp from 'jsonp';

import { moduleDecorators } from '@/common/loading';
import { EmailPayload } from '@/models/AnswerModel';
import { api } from '@/tools/api';
import { createStoreClassInstance } from '@/tools/decorators';

import getters from './getters';
import mutations from './mutations';
import state from './state';
type Ctx = ActionContext<typeof state, typeof getters, typeof mutations>;

const { loading } = moduleDecorators('questions');

export class QuestionsActions {
	@loading()
	async sendEmail(ctx: Ctx, data: EmailPayload) {
		const result = await api.post<any>(`api/mail`, data, {
			suppressToken: true
		});

		return result;
	}

	@loading()
	async subscribeMailchimp(ctx: Ctx, data: string) {
		return await new Promise((res, rej) => {
			const server = 'https://vetoquinol.us15.list-manage.com';
			const url = `${server}/subscribe/post-json?${data}`;

			jsonp(url, { param: 'c' }, (err, data) => {
				err ? rej(err) : res(data);
			});
		});
	}
}

export default createStoreClassInstance(QuestionsActions);
