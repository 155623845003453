import { Plugin } from 'vue';

import { $isLoading } from './helper';

export const loadingStatePlugin: Plugin = app => {
	app.config.globalProperties.$isLoading = $isLoading;
};

declare module '@vue/runtime-core' {
	interface ComponentCustomProperties {
		$isLoading: typeof $isLoading;
	}
}
