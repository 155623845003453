import { computed, ref, watch, WritableComputedRef } from 'vue';

import { EmitFn } from '@/tools/types';

export function useVModelProxy<
	Props extends { modelValue?: unknown },
	E extends ['update:modelValue']
>(props: Props, emit: EmitFn<E>): WritableComputedRef<Props['modelValue']>;

export function useVModelProxy<
	Key extends string,
	Props extends { [key in Key]?: any },
	// eslint-disable-next-line prettier/prettier
	E extends [`update:${Key}`]
>(props: Props, emit: EmitFn<E>, modelKey: Key): WritableComputedRef<Props[Key]>;

export function useVModelProxy(props: Record<string, any>, emit: EmitFn, modelKey = 'modelValue') {
	const state = ref<unknown>(props[modelKey]);
	const model = computed({
		get() {
			return state.value;
		},
		set(value) {
			state.value = value;
			emit(`update:${modelKey}`, value);
		}
	});

	watch(
		() => props[modelKey],
		() => {
			if (state.value !== props[modelKey]) {
				state.value = props[modelKey];
			}
		}
	);

	return model;
}
