<template>
	<div class="form-item" :class="{ 'form-item--error': error }">
		<label v-if="showLabel" class="form-item__label" :for="$props.for">
			<slot name="label">
				{{ label }}
			</slot>
		</label>

		<slot />

		<error-message v-if="error" class="form-item__error">
			<slot name="error" v-bind="{ error }">
				{{ error }}
			</slot>
		</error-message>
	</div>
</template>

<script lang="ts">
import { useField, useFieldError } from 'vee-validate';
import { computed, defineComponent, provide, ref } from 'vue';

import { errorDisplayKey } from '@/common/validation';
import { useHasSlot } from '@/composables';

export default defineComponent({
	props: {
		for: {
			type: String,
			default: ''
		},

		label: {
			type: String,
			default: ''
		},

		name: {
			type: String,
			default: ''
		}
	},

	setup(props, { slots }) {
		const errorDisplay = ref<string>('');
		const fieldError = props.name ? useFieldError(props.name) : undefined;
		const fieldMeta = props.name ? useField(props.name) : undefined;

		provide(errorDisplayKey, errorDisplay);

		return {
			showLabel: useHasSlot('label', slots, hasSlot => hasSlot || !!props.label),
			error: computed(() => fieldError?.value ?? errorDisplay.value),
			meta: computed(() => fieldMeta?.value)
		};
	}
});
</script>

<style lang="scss">
.form-item {
	margin-bottom: 2.1rem;
	text-align: left;

	&__error {
		padding-top: 0.45em;
		position: absolute;
	}
}
</style>
