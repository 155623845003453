<template>
	<!-- <div id="nav">
		<router-link to="/">Home</router-link>
		|
		<router-link to="/about">About</router-link>
	</div> -->

	<div class="layout-default">
		<slot />
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
