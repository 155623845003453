import dayjs from 'dayjs';

import { AnswerColumn } from '@/models/AnswerModel';
import { QuestionModel } from '@/models/QuestionModel';
import {
	addDoubleOptionalUnit,
	addDoubleUnit,
	addSingleUnit,
	assureFutureDate,
	defaultQuestionHeadline,
	isBeforeMonthFromNowOnUnit,
	isBeforeMonthsFromNowOnOptionalUnit,
	isBeforeMonthsFromNowOnUnit
} from '@/tools/helpers/finalDateHelpers';

export const DogQuestions: QuestionModel[] = [
	{
		id: 1,
		type: 'boolean',
		title: 'W jakim wieku jest Twój pies?',
		data: {
			left: {
				label: 'Dorosły',
				subTitle: 'powyżej 6 miesięcy',
				nextQuestion: 4
			},
			right: {
				label: 'Szczenię',
				subTitle: 'Od 2. tygodnia do 6. miesiąca życia',
				nextQuestion: 3
			}
		}
	},
	{
		id: 3,
		type: 'email',
		title: 'Zalecenia dla szczeniaka',
		data: {
			recommendations:
				'Od 2. tygodnia życia, następnie <strong>co 14 dni</strong> do 2 tygodni po odsadzeniu. Kolejne zabiegi <strong>co 4 tygodnie</strong> do 6. miesiąca życia.',
			additionalNotes:
				'Twój pupil jest teraz w szczególnym okresie swojego życia i wymaga opieki lekarza weterynarii, który to doradzi Ci dobór substancji, dawkę i częstotliwość podania leku.'
		}
	},
	{
		id: 4,
		type: 'boolean',
		title: 'Jakiej płci jest Twój pies?',
		data: {
			left: {
				label: 'Samiec',
				nextQuestion: 9
			},
			right: {
				label: 'Samica',
				nextQuestion: 5
			}
		}
	},
	{
		id: 5,
		type: 'boolean',
		title: 'Czy suka jest ciężarna?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 7
			},
			right: {
				label: 'Tak',
				nextQuestion: 6
			}
		}
	},
	{
		id: 6,
		type: 'email',
		title: 'Zalecenia dla suk ciężarnych',
		data: {
			recommendations:
				'Aby zapobiec transmisji Toxocara do szczeniąt, szczenne suki powinny otrzymać preparat <strong>w 40. i 55. dniu</strong> ciąży lub <strong>codziennie</strong> od 40. dnia ciąży do 14. dnia po porodzie.',
			additionalNotes:
				'Twój pupil jest teraz w szczególnym okresie swojego życia i wymaga opieki lekarza weterynarii, który to doradzi Ci dobór substancji, dawkę i częstotliwość podania leku.',
			calculateDates: () => []
		}
	},
	{
		id: 7,
		type: 'boolean',
		title: 'Czy suka jest karmiąca?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 9
			},
			right: {
				label: 'Tak',
				nextQuestion: 8
			}
		}
	},
	{
		id: 8,
		type: 'email',
		title: 'Zalecenia dla suk karmiących',
		data: {
			recommendations:
				'Powinny być leczone łącznie ze szczeniętami <strong>2 tygodnie</strong> po urodzeniu, następnie <strong>co 14 dni</strong> do momentu odsadzenia.',
			additionalNotes:
				'Twój pupil jest teraz w szczególnym okresie swojego życia i wymaga opieki lekarza weterynarii, który to doradzi Ci dobór substancji, dawkę i częstotliwość podania leku.',
			calculateDates: () => []
		}
	},
	{
		id: 9,
		type: 'boolean',
		title: 'Czy przebywa w warunkach zwiększonego ryzyka: hodowle?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 23
			},
			right: {
				label: 'Tak',
				nextQuestion: 10
			}
		}
	},
	{
		id: 10,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: false,
			nextQuestion: 11
		}
	},
	{
		id: 11,
		type: 'email',
		title: 'zwiększone ryzyko zarażenia',
		data: {
			recommendations:
				'W hodowlach leczenie <strong>co 4 tygodnie</strong> (jeśli nie są wykonywane regularne badania kału).',
			calculateDates: answers => {
				return addSingleUnit(answers, 10, 4, 'week');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthFromNowOnUnit(answers, 10);
			}
		}
	},
	{
		id: 12,
		type: 'email',
		title: 'zwiększone ryzyko zarażenia',
		data: {
			recommendations:
				'W hodowlach leczenie <strong>co 4 tygodnie</strong> (jeśli nie są wykonywane regularne badania kału).',
			calculateDates: answers => {
				const answer: any = answers[24]?.answer || '';
				const retVal: Array<AnswerColumn> = [];

				if (!answer) {
					return [];
				}

				const exhibitionDate = assureFutureDate(answer, 0);

				retVal.push({
					headline: 'Najbliższe <br/>przed wystawą',
					dates: [exhibitionDate.subtract(1, 'month')]
				});
				retVal.push({
					headline: 'Najbliższe <br/>po wystawie',
					dates: [exhibitionDate.add(2, 'week')]
				});

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthFromNowOnUnit(answers, 24);
			}
		}
	},
	{
		id: 13,
		type: 'email',
		title: 'Psy na służbie',
		data: {
			recommendations:
				'<strong>12 razy</strong> do roku, aby wykluczyć możliwość wydalania jaj nicieni.',
			calculateDates: answers => {
				return addDoubleOptionalUnit(answers, 26, 1, 'month', 43, 1, 'month');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(1, answers, 26);
			}
		}
	},
	{
		id: 14,
		type: 'email',
		title: 'Psy z dziećmi lub osobami z obniżoną odpornością',
		data: {
			recommendations:
				'<strong>12 razy</strong> do roku, aby wykluczyć możliwość wydalania jaj nicieni.',
			calculateDates: answers => {
				return addDoubleOptionalUnit(answers, 28, 1, 'month', 48, 1, 'month');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(1, answers, 28);
			}
		}
	},
	{
		id: 15,
		type: 'email',
		title: 'Surowe mięso, dieta barf',
		data: {
			recommendations:
				'Psy karmione surowym mięsem powinny być leczone <strong>co 6 tygodni</strong> przeciwko tasiemcom.',
			calculateDates: answers => {
				return addDoubleOptionalUnit(answers, 36, 6, 'week', 44, 6, 'week');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(2, answers, 36);
			}
		}
	},
	{
		id: 16,
		type: 'email',
		title: 'Infestacja pcheł',
		data: {
			recommendations:
				'Leczenie <strong>jednokrotne</strong> po stwierdzeniu infestacji pcheł, następnie <strong>cyklicznie</strong> według zasad wskazanych dla psów bez infestacji pcheł.',
			calculateDates: () => []
		}
	},
	{
		id: 17,
		type: 'email',
		title: 'Grupa ryzyka D',
		data: {
			recommendations:
				'Odrobaczać <strong>12 razy do roku</strong> przeciwko nicieniom i tasiemcom lub wykonać badanie kału.',
			calculateDates: answers => {
				const answer: any = answers[37]?.answer || '';
				const dateSelected = !(answer === true || answer === false);
				const willDewormIn: any = answers[45]?.answer ? 1 : 2;

				const retVal: Array<AnswerColumn> = [];

				if (dateSelected) {
					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [assureFutureDate(answer, 1, 'month')]
					});

					return retVal;
				} else {
					const today = dayjs();
					const willDewormInDate = today.add(willDewormIn, 'week');

					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [willDewormInDate, willDewormInDate.add(1, 'month')]
					});
				}

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(1, answers, 37);
			}
		}
	},
	{
		id: 18,
		type: 'email',
		title: 'Grupa ryzyka D',
		data: {
			recommendations:
				'Odrobaczać <strong>12 razy do roku</strong> przeciwko nicieniom i tasiemcom lub wykonać badanie kału.',
			calculateDates: answers => {
				return addDoubleUnit(answers, 39, 1, 'month', 1, 'week');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnUnit(1, answers, 39);
			}
		}
	},
	{
		id: 19,
		type: 'email',
		title: 'Grupa ryzyka A',
		data: {
			recommendations:
				'Odrobaczać <strong>1-2 razy do roku</strong> przeciwko nicieniom i tasiemcom lub wykonać badanie kału.',
			calculateDates: answers => {
				return addDoubleOptionalUnit(answers, 38, 6, 'month', 49, 6, 'month');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(6, answers, 38);
			}
		}
	},
	{
		id: 20,
		type: 'email',
		title: 'Grupa ryzyka C',
		data: {
			recommendations:
				'Odrobaczać <strong>12 razy do roku</strong> przeciwko tasiemcom, <strong>4 razy do roku</strong> przeciwko nicieniom lub wykonać badanie kału.',
			calculateDates: answers => {
				const answer: any = answers[41]?.answer || '';
				const dateSelected = !(answer === true || answer === false);
				const willDewormIn: any = answers[47]?.answer ? 1 : 2;
				const retVal: Array<AnswerColumn> = [];

				if (dateSelected) {
					retVal.push({
						headline: defaultQuestionHeadline,
						// tasiemce + nicenie
						dates: [assureFutureDate(answer, 1, 'month'), assureFutureDate(answer, 3, 'month')]
					});

					return retVal;
				} else {
					const today = dayjs();
					const willDewormInDate = today.add(willDewormIn, 'week');

					retVal.push({
						headline: defaultQuestionHeadline,
						dates: [
							willDewormInDate,
							willDewormInDate.add(1, 'month'), // tasiemce
							willDewormInDate.add(3, 'month') // nicenie
						]
					});
				}

				return retVal;
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(1, answers, 41);
			}
		}
	},
	{
		id: 21,
		type: 'email',
		title: 'Grupa ryzyka B',
		data: {
			recommendations:
				'Odrobaczać <strong>4 razy do roku</strong> przeciwko nicieniom i tasiemcom lub wykonać badanie kału.',
			calculateDates: answers => {
				return addDoubleOptionalUnit(answers, 40, 3, 'month', 46, 3, 'month');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnOptionalUnit(3, answers, 40);
			}
		}
	},
	{
		id: 23,
		type: 'boolean',
		title: 'Czy przebywa w warunkach zwiększonego ryzyka: wystawy?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 25
			},
			right: {
				label: 'Tak',
				nextQuestion: 24
			}
		}
	},
	{
		id: 24,
		type: 'date',
		title: 'Kiedy jest najbliższa planowana wystawa?',
		data: {
			optional: false,
			nextQuestion: 12
		}
	},
	{
		id: 25,
		type: 'boolean',
		title: 'Pies pracujący: ratownictwo, policja, psy przewodnicy',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 27
			},
			right: {
				label: 'Tak',
				nextQuestion: 26
			}
		}
	},
	{
		id: 26,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 13,
			nextOptionalQuestion: 43
		}
	},
	{
		id: 27,
		type: 'boolean',
		title: 'Czy przebywa w domu z małymi dziećmi lub osobami z obniżoną odpornością?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 30
			},
			right: {
				label: 'Tak',
				nextQuestion: 28
			}
		}
	},
	{
		id: 28,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 14,
			nextOptionalQuestion: 48
		}
	},
	{
		id: 29,
		type: 'boolean',
		title: 'Czy pies Jest żywiony surowym mięsem lub dietą BARF?',
		subTitle:
			'(dotyczy surowego mięsa nie poddanego mrożeniu minimum 7 dni w temp. od -17 do -20 st.)',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 9
			},
			right: {
				label: 'Tak',
				nextQuestion: 36
			}
		}
	},
	{
		id: 30,
		type: 'boolean',
		title: 'Czy zaobserwowałeś u swojego psa pasożyty zewnętrzne – pchły?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 31
			},
			right: {
				label: 'Tak',
				nextQuestion: 16
			}
		}
	},
	{
		id: 37,
		type: 'email',
		title: 'Grupa ryzyka B',
		data: {
			recommendations:
				'Odrobaczać <strong>4 razy do roku</strong> przeciwko nicieniom i tasiemcom lub wykonać badanie kału.',
			calculateDates: answers => {
				return addDoubleUnit(answers, 32, 3, 'month', 1, 'week');
			},
			shouldBeDewormedNow: answers => {
				return isBeforeMonthsFromNowOnUnit(3, answers, 32);
			}
		}
	},
	{
		id: 31,
		type: 'boolean',
		title: 'Czy pies biega swobodnie?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 33
			},
			right: {
				label: 'Tak',
				nextQuestion: 32
			}
		}
	},
	{
		id: 32,
		type: 'boolean',
		title: 'Czy pies biega bez dozoru opiekuna?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 33
			},
			right: {
				label: 'Tak',
				nextQuestion: 37
			}
		}
	},
	{
		id: 33,
		type: 'boolean',
		title: 'Czy pies ma kontakt z innymi psami / innymi zwierzętami?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 38
			},
			right: {
				label: 'Tak',
				nextQuestion: 34
			}
		}
	},
	{
		id: 34,
		type: 'boolean',
		title: 'Czy zjada padlinę lub odchody innych zwierząt?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 35
			},
			right: {
				label: 'Tak',
				nextQuestion: 37
			}
		}
	},
	{
		id: 35,
		type: 'boolean',
		title: 'Czy pies poluje i zjada upolowane ofiary?',
		data: {
			left: {
				label: 'Nie',
				nextQuestion: 40
			},
			right: {
				label: 'Tak',
				nextQuestion: 41
			}
		}
	},
	{
		id: 36,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 15,
			nextOptionalQuestion: 44
		}
	},
	{
		id: 37,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 17, // ryzyko D
			nextOptionalQuestion: 45
		}
	},
	{
		id: 38,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 19, //ryzyko A
			nextOptionalQuestion: 49
		}
	},
	{
		id: 39,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 18 // ryzyko D
		}
	},
	{
		id: 40,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 21, // ryzyko B
			nextOptionalQuestion: 46
		}
	},
	{
		id: 41,
		type: 'date',
		title: 'Twój pies ostatnio odrobaczany był...',
		data: {
			optional: true,
			optionalText: 'Nie pamiętam / nie był odrobaczany',
			nextQuestion: 20, // ryzyko C
			nextOptionalQuestion: 47
		}
	},
	{
		id: 43,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 13
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 13
			}
		}
	},
	{
		id: 44,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 15
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 15
			}
		}
	},
	{
		id: 45,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 17
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 17
			}
		}
	},
	{
		id: 46,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 21 // ryzyko B
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 21 // ryzyko B
			}
		}
	},
	{
		id: 47,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 20 // ryzyko C
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 20 // ryzyko C
			}
		}
	},
	{
		id: 48,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 14 // odpornosc
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 14 // odpornosc
			}
		}
	},
	{
		id: 49,
		type: 'boolean',
		title: 'Odrobaczę psa w ciągu:',
		data: {
			left: {
				label: 'Tygodnia',
				nextQuestion: 19 // ryzyko a
			},
			right: {
				label: '2 tygodni',
				nextQuestion: 19 // ryzyko a
			}
		}
	}
];
