import { defineModule } from '@owlsdepartment/vuex-typed';
import { getModule } from '@owlsdepartment/vuex-typed';
import { Dictionary, isString } from 'lodash';

export const LOADING_MODULE_KEY = 'loading';

export const loadingModule = defineModule({
	namespaced: true,

	state: {
		loadings: {} as Dictionary<boolean>
	},

	getters: {
		getLoadingStatesForActions: state => (actions: string | string[]) => {
			if (isString(actions)) {
				return !!state.loadings[actions];
			}

			return actions.some(action => state.loadings[action]);
		}
	},

	mutations: {
		changeLoadingState(state, [action, value]: [string, boolean]) {
			state.loadings[action] = value;
		},

		resetState(state) {
			state.loadings = {};
		}
	}
});

export const { getters: loadingGetters, mutations: loadingMutations } = getModule(
	loadingModule,
	LOADING_MODULE_KEY
);
