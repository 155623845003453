import dayjs, { Dayjs } from 'dayjs';

import { AnswerColumn } from '@/models/AnswerModel';
import { DateOffsetType } from '@/tools/types/basic';

export const defaultQuestionHeadline = 'POWTÓRNE ODROBACZANIE';

export function addSingleUnit(
	answers: any,
	answerDateIndex: number,
	howMuch = 1,
	unitType: DateOffsetType = 'month',
	headline: string = defaultQuestionHeadline
): AnswerColumn[] {
	let retVal: Array<AnswerColumn> = [];
	const answer: any = answers[answerDateIndex]?.answer;

	if (answer === true) {
		return [];
	}

	retVal = [{ headline, dates: [assureFutureDate(answer, howMuch, unitType)] }];

	return retVal;
}

export function addDoubleUnit(
	answers: any,
	answerDateIndex: number,
	howMuch = 1,
	unitType: DateOffsetType = 'month',
	optionalHowMuch = 1,
	optionalUnitType: DateOffsetType = 'month',
	headline: string = defaultQuestionHeadline
): AnswerColumn[] {
	const answer: any = answers[answerDateIndex]?.answer;
	const retVal: Array<AnswerColumn> = [];

	if (answer === true) {
		const today = dayjs();

		retVal.push({
			headline,
			dates: [today, today.add(optionalHowMuch, optionalUnitType)]
		});

		return retVal;
	}

	retVal.push({
		headline,
		dates: [assureFutureDate(answer, howMuch, unitType)]
	});

	return retVal;
}

export function addDoubleOptionalUnit(
	answers: any,
	answerDateIndex: number,
	howMuch = 1,
	unitType: DateOffsetType = 'month',
	optionalIndex: number,
	optionalHowMuch = 1,
	optionalUnitType: DateOffsetType = 'month',
	headline: string = defaultQuestionHeadline
): AnswerColumn[] {
	const answer: any = answers[answerDateIndex]?.answer || '';
	const dateSelected = !(answer === true || answer === false);
	const willDewormIn: any = answers[optionalIndex]?.answer ? 1 : 2;

	const retVal: Array<AnswerColumn> = [];

	if (dateSelected) {
		retVal.push({
			headline,
			dates: [assureFutureDate(answer, howMuch, unitType)]
		});

		return retVal;
	} else {
		const today = dayjs();
		const willDewormInDate = today.add(willDewormIn, 'week');

		retVal.push({
			headline,
			dates: [willDewormInDate, willDewormInDate.add(optionalHowMuch, optionalUnitType)]
		});
	}

	return retVal;
}

export function isBeforeMonthsFromNowOnUnit(
	months: number,
	answers: any,
	answerDateIndex: number
): boolean {
	const answer: any = answers[answerDateIndex]?.answer;

	if (answer === true) {
		return false;
	}

	return isBeforeMonthsFromNow(months, answer);
}

export function isBeforeMonthFromNowOnUnit(answers: any, answerDateIndex: number): boolean {
	return isBeforeMonthsFromNowOnUnit(1, answers, answerDateIndex);
}

export function isBeforeMonthsFromNowOnOptionalUnit(
	months: number,
	answers: any,
	answerDateIndex: number
): boolean {
	const answer: any = answers[answerDateIndex]?.answer;
	const dateSelected = !(answer === true || answer === false);

	if (!dateSelected) {
		return false;
	}

	return isBeforeMonthsFromNow(months, answer);
}

export function isBeforeMonthFromNowOnOptionalUnit(answers: any, answerDateIndex: number): boolean {
	return isBeforeMonthsFromNowOnOptionalUnit(1, answers, answerDateIndex);
}

export function isBeforeMonthFromNow(answerDate: string): boolean {
	return isBeforeMonthsFromNow(1, answerDate);
}

export function isBeforeMonthsFromNow(months: number, answerDate: string): boolean {
	return dayjs(answerDate).isBefore(dayjs().subtract(months, 'month'));
}

export function assureFutureDate(
	answerDate: string,
	howMuch = 1,
	unitType: DateOffsetType = 'month'
): Dayjs {
	let day = dayjs(answerDate).add(howMuch, unitType);

	if (day.isBefore(dayjs())) {
		day = dayjs().add(howMuch, unitType);
	}

	return day;
}
