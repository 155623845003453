
import { computed, defineComponent } from 'vue';

import { useGetImage } from '@/composables';
import { questionsGetters, questionsMutations, questionsState } from '@/store/modules/questions';

export default defineComponent({
	props: {
		showBack: {
			type: Boolean,
			default: true
		}
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			logo: getHomeImage('logo-veto'),

			currentQuestionIndex: computed(questionsState.currentQuestionIndex),
			currentQuestion: computed(questionsGetters.currentQuestion)
		};
	},

	methods: {
		onClickBack() {
			if (this.$route.name === 'questions') {
				this.$router.push({ name: 'home' });
			} else if (this.currentQuestionIndex === 0) {
				this.$router.push({ name: 'questions' });
			} else {
				questionsMutations.setPrevCurrent();
			}
		}
	}
});
