import { isString } from 'lodash';

import { loadingGetters } from './loadingModule';

export function $isLoading(actions?: string | string[]): boolean {
	if (!actions) {
		return false;
	}

	if (isString(actions)) {
		actions = [actions];
	} else if (!Array.isArray(actions)) {
		throw `Argument was expected to be a string | string[] but got ${typeof actions}`;
	}

	return loadingGetters.getLoadingStatesForActions()(actions);
}
