import { gsap, Power3 } from 'gsap';
import { Dictionary } from 'lodash';
import { computed, ref, Slots, watch } from 'vue';

import { useHasSlot, useVModelProxy } from '@/composables';
import { EmitFn } from '@/tools/types';

export interface AccordionCoreProps {
	expanded: boolean;
	disabled: boolean;
}

export interface AccordionCoreOptions {
	minHeight?: number | string;
}

const DURATION = 0.4;
const ANIMATION_DEFAULTS: gsap.TweenVars = { duration: DURATION, ease: Power3.easeOut };
let _id = 0;

export function useAccordionCore(
	props: AccordionCoreProps,
	slots: Slots,
	emit: EmitFn<['update:expanded']>,
	{ minHeight = 0 }: AccordionCoreOptions = {}
) {
	const id = _id++;
	const contentRef = ref<HTMLElement | null>(null);
	const contentID = `accordion-content-${id}`;
	const triggerID = `accordion-trigger-${id}`;

	const isExpanded = useVModelProxy(props, emit, 'expanded');
	const hasContent = useHasSlot('default', slots, slot => slot && !props.disabled);

	const stopContentWatch = watch(contentRef, contentEl => {
		if (contentEl) {
			if (!isExpanded.value) {
				gsap.set(contentRef.value, { height: minHeight });
			}

			stopContentWatch();
		}
	});

	watch(isExpanded, expanded => {
		gsap.killTweensOf(contentRef.value);
		expanded ? showContent() : hideContent();
	});

	function showContent() {
		gsap.fromTo(
			contentRef.value,
			{ height: minHeight },
			{
				...ANIMATION_DEFAULTS,
				height: 'auto'
			}
		);
	}

	function hideContent() {
		gsap.fromTo(
			contentRef.value,
			{ height: contentRef.value?.scrollHeight ?? 'auto' },
			{
				...ANIMATION_DEFAULTS,
				height: minHeight
			}
		);
	}

	return {
		id,
		contentID,
		triggerID,
		isExpanded,
		hasContent,
		contentRef,

		triggerTag: computed(() => (hasContent.value ? 'button' : 'div')),
		triggerAttributes: computed(
			(): Dictionary<string> => ({
				id: triggerID,
				...(hasContent.value ? { 'aria-controls': contentID } : {})
			})
		),

		onToggle: () => {
			if (!hasContent.value) {
				return;
			}

			isExpanded.value = !isExpanded.value;
		}
	};
}
