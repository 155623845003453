/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dictionary, extend } from 'lodash';

type FormDataValue = Parameters<FormData['append']>[1];
export type FormDataDict = Dictionary<FormDataValue>;

interface WithFormData extends AxiosRequestConfig {
	formData: true;
}

interface WithOptionalFormData extends AxiosRequestConfig {
	formData?: boolean;
}

export type ApiUploadMethod = 'post' | 'put' | 'patch';

export interface ApiUploadOptions {
	onUploadProgress?: (progressEvent: any) => void;
	method?: ApiUploadMethod;
	params?: Record<string, string | Blob>;
	config?: AxiosRequestConfig;
}

class Api {
	get<R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
		url = normalizeUrl(url);

		return unwrapData(axios.get<R>(url, config));
	}

	post<R = any>(url: string, data: FormDataDict, config: WithFormData): Promise<R>;
	post<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
	post<R = any>(url: string, data?: any, config?: WithOptionalFormData): Promise<R> {
		url = normalizeUrl(url);

		if (config?.formData) {
			data = convertToFormData(data);
		}

		return unwrapData(axios.post<R>(url, data, config));
	}

	put<R = any>(url: string, data: FormDataDict, config: WithFormData): Promise<R>;
	put<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
	put<R = any>(url: string, data?: any, config?: WithOptionalFormData): Promise<R> {
		url = normalizeUrl(url);

		if (config?.formData) {
			data = convertToFormData(data);
			data.append('_method', 'PATCH');
		}

		return unwrapData(axios.put<R>(url, data, config));
	}

	patch<R = any>(url: string, data: FormDataDict, config: WithFormData): Promise<R>;
	patch<R = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<R>;
	patch<R = any>(url: string, data?: any, config?: WithOptionalFormData): Promise<R> {
		url = normalizeUrl(url);

		if (config?.formData) {
			data = convertToFormData(data);
			data.append('_method', 'PATCH');
		}

		return unwrapData(axios.patch<R>(url, data, config));
	}

	delete<R = any>(url: string, config?: AxiosRequestConfig): Promise<R> {
		url = normalizeUrl(url);

		return unwrapData(axios.delete<R>(url, config));
	}

	upload(url: string, inputName: string, file: File, options: ApiUploadOptions = {}) {
		const { method = 'post', config = {}, onUploadProgress } = options;
		const uploadConfig = extend(config, { onUploadProgress });
		const data = new FormData();

		Object.entries(options.params ?? {}).forEach(([key, value]) => data.append(key, value));

		data.append(inputName, file);
		url = normalizeUrl(url);

		return unwrapData(axios[method](url, data, uploadConfig));
	}
}

function unwrapData<R>(request: Promise<AxiosResponse<R>>) {
	return request.then(res => res.data);
}

function normalizeUrl(url: string) {
	return url.replace(/^\//gm, '');
}

function convertToFormData(data: FormDataDict) {
	const formData = new FormData();

	Object.entries(data as FormDataDict).forEach(([key, val]) => formData.append(key, val));

	return formData;
}

export default new Api();
