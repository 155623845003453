<template>
	<div
		v-click-outside="onClickOutside"
		class="dropdown"
		:class="{ 'dropdown--is-opened': isOpened, 'dropdown--transparent': transparent }"
	>
		<div
			class="dropdown__label"
			tabindex="0"
			@click="toggleDropdown"
			@keyup.enter="toggleDropdown"
			@keydown.down="onKeydownLabel"
		>
			{{ currentValue }}
			<img src="@/assets/images/global/arrow-bottom-gray.svg" alt="" class="dropdown__arrow" />
		</div>
		<div class="dropdown__values">
			<div
				v-for="(value, index) in values"
				:key="value"
				:ref="`dropdownValues_${index}`"
				:class="{ 'dropdown__values--current': value === currentValue }"
				class="dropdown__value"
				tabindex="0"
				@click="changeValue(value)"
				@keyup.enter="changeValue(value)"
				@keydown.up.down="onKeydownInside($event)"
			>
				{{ value }}
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
	name: 'Dropdown',
	props: {
		selected: {
			type: [Number, String],
			required: true
		},
		values: {
			type: Array,
			required: true
		},
		transparent: {
			type: Boolean
		}
	},
	emits: ['update'],
	setup() {
		const dropdownValues_0 = ref<HTMLElement | null>(null);

		function onKeydownLabel() {
			if (dropdownValues_0.value) {
				dropdownValues_0.value.focus();
			}
		}

		function onKeydownInside(event: any) {
			event.preventDefault();

			if (event.code === 'ArrowDown') {
				if (event?.target?.nextElementSibling) {
					event.target.nextElementSibling.focus();
				}
			} else {
				if (event?.target?.previousElementSibling) {
					event.target.previousElementSibling.focus();
				}
			}
		}

		return {
			onKeydownLabel,
			onKeydownInside,
			dropdownValues_0
		};
	},
	data() {
		return {
			isOpened: false,
			currentValue: this.selected
		};
	},
	methods: {
		toggleDropdown() {
			this.isOpened = !this.isOpened;
		},
		changeValue(value: any) {
			this.currentValue = value;
			this.$emit('update', this.currentValue);
			this.isOpened = false;
		},
		onClickOutside() {
			this.isOpened = false;
		}
	}
});
</script>

<style lang="scss" scoped>
.dropdown {
	@include inline-flex-center;
	@include font-inter;

	position: relative;
	z-index: $z_index_over_content;
	line-height: (24 / 14);

	.dropdown__label {
		@include inline-flex-center;

		min-height: 4rem;
		padding: 8px 36px 6px 9px;
		position: relative;
		font-size: 14px;
		font-weight: 600;
		text-transform: capitalize;
		cursor: pointer;
		transition: background-color 300ms ease;
		border-radius: 0.8rem;
		background: $color_gray_lighter;

		@include max-mobile {
			padding: 8px 36px 6px 16px;
		}

		.dropdown__arrow {
			position: absolute;
			top: calc(50% - 2px);
			right: 18px;
		}

		&:hover {
			background: darken($color_gray_lighter, 2%);
		}
	}

	.dropdown__values {
		display: none;
		min-width: 100%;
		max-height: 25vh;
		padding: 3px 0;
		position: absolute;
		top: 100%;
		left: 0;
		text-transform: capitalize;
		overflow: auto;
		border: 1px solid darken($color_gray_lighter, 5%);
		border-radius: 3px;
		background: white;
	}

	.dropdown__value {
		margin: 2px 0;
		padding: 2px 10px;
		font-size: 14px;
		font-weight: 300;
		text-align: left;
		cursor: pointer;

		&.dropdown__values--current {
			font-weight: 600;
		}

		&:hover {
			opacity: 0.7;
		}
	}

	&.dropdown--transparent {
		.dropdown__label {
			background: transparent;

			@include max-mobile {
				padding: 8px 36px 6px 13px;
			}
		}
	}

	&.dropdown--is-opened {
		.dropdown__label {
			background: darken($color_gray_lighter, 4%);
		}

		.dropdown__values {
			display: block;
		}

		.dropdown__arrow {
			transform: rotate(180deg);
		}
	}
}
</style>
