import { defineRule } from 'vee-validate';

import { checked, confirmPassword, dateRequired, email, noWhitespaceOnly, required } from '.';

defineRule('checked', checked);
defineRule('email', email);
defineRule('required', required);
defineRule('no-whitespace-only', noWhitespaceOnly);
defineRule('confirm-password', confirmPassword);
defineRule('date-required', dateRequired);
