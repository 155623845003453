import { isArray, isNumber, isObject, isString } from 'lodash';

import { PresetFunction } from '../loadingDecorator';

type IdPresetVal = string | number | boolean;

declare module '@/common/loading' {
	export interface Presets {
		id?: IdPresetVal;
	}
}

export const idPreset: PresetFunction = (value: IdPresetVal, { args, basicKey }) => {
	if (!value) {
		return null;
	}

	const idKey = isString(value) ? value : 'id';
	const arg = args[0];
	let id: string | number = '';

	if (isObject(arg)) {
		id = (arg as any)[idKey];
	} else if (isString(arg) || isNumber(arg)) {
		id = arg;
	} else if (isArray(arg)) {
		return arg.map(val => `${basicKey}/${val}`);
	} else {
		return basicKey;
	}

	return `${basicKey}/${id}`;
};
