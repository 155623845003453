<template>
	<teleport v-if="wasMounted" v-bind="$props">
		<slot />
	</teleport>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';

export default defineComponent({
	props: {
		to: {
			type: String,
			default: ''
		},

		disabled: {
			type: Boolean
		}
	},

	setup() {
		const wasMounted = ref(false);

		onMounted(() => {
			wasMounted.value = true;
		});

		return {
			wasMounted
		};
	}
});
</script>
