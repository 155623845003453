<template>
	<form class="base-form" @submit.prevent="submitForm">
		<slot v-bind="forSlot" />
	</form>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { useForm, ValidationSchema } from '@/common/validation';
import { AnyObject } from '@/tools/types';

export default defineComponent({
	props: {
		/**
		 * Initial form values
		 */
		initial: {
			type: Object,
			default: () => ({} as AnyObject)
		},
		/**
		 * You cannot use per field validation and schema validation simultaneously, as schema always takes priority.
		 * Empty object, passed as validation schema, is treated as validation, thus disabling per field validation.
		 */
		validationSchema: {
			type: Object as PropType<ValidationSchema>,
			default: () => undefined
		},

		validateOnMount: {
			type: Boolean
		},

		errors: {
			type: Object,
			default: () => ({})
		},

		registerName: {
			type: String,
			default: ''
		}
	},

	emits: ['submit', 'reset', 'update:errors'],

	setup(props, { emit }) {
		const { context, forSlot } = useForm(props, emit);

		return {
			submitForm: context.submitForm,
			forSlot
		};
	}
});
</script>
