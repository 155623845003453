<template>
	<div class="form-datepicker before-init">
		<form-field v-slot="{ field, errors }" v-model="model" v-bind="$props">
			<base-datepicker v-bind="{ ...field, ...$attrs }" :has-error="!!errors.length">
				<template v-if="hasFooter" #footer>
					<slot name="footer" />
				</template>

				<template #bottom>
					<slot name="bottom" />
				</template>
			</base-datepicker>
		</form-field>
	</div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { useFormFieldWrapper } from '@/common/validation/composables';
import { ValidationRule } from '@/common/validation/helpers';
import { useHasSlot } from '@/composables';

export default defineComponent({
	inheritAttrs: false,

	props: {
		name: {
			type: String,
			required: true
		},

		rules: {
			type: [String, Function, Array, Object] as PropType<ValidationRule>,
			default: () => []
		},

		modelValue: {
			type: Object as PropType<Date>,
			default: null
		}
	},

	emit: ['update:modelValue'],

	setup(props, { emit, slots }) {
		return {
			hasFooter: useHasSlot('footer', slots),

			...useFormFieldWrapper(props, emit)
		};
	}
});
</script>

<style lang="scss">
.form-datepicker {
	@include max-phablet {
		padding: 0 8px;
	}
}
</style>
