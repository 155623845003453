import { Component, Plugin } from 'vue';

interface ComponentEntries {
	[key: string]: Component;
}

/**
 * Adds components in `@/components/base` to global app scope
 */
export const globalComponentsPlugin = (globalComponents: ComponentEntries[]): Plugin => app => {
	for (const components of globalComponents) {
		Object.keys(components).forEach(key => {
			app.component(key, components[key]);
		});
	}
};
