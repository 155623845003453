
import { computed, defineComponent, PropType } from 'vue';

import { useHasSlot, useVModelProxy } from '@/composables';
import { AnyObject } from '@/tools/types';

export type RadioValue = boolean | number | string;

export interface RadioOption {
	label?: string;
	value: RadioValue;
	slug?: string;
}

export default defineComponent({
	props: {
		/**
		 * Native input `name`
		 */
		name: {
			type: String,
			default: ''
		},
		/**
		 * Optional fieldset `legend`
		 */
		legend: {
			type: String,
			default: ''
		},
		/**
		 * v-model prop
		 */
		modelValue: {
			type: [Number, String, Boolean] as PropType<RadioValue>,
			default: ''
		},
		/**
		 * All radio options, rendered as inputs
		 */
		options: {
			type: Array as PropType<RadioOption[]>,
			default: () => [] as RadioOption[]
		}
	},

	emits: ['update:modelValue', 'change'],

	setup(props, { emit, slots }) {
		const model = useVModelProxy(props, emit);

		return {
			model,

			hasLegend: useHasSlot('legend', slots, slot => slot || !!props.legend),
			onChange: () => {
				emit('change', model.value);
			},
			getLabel: computed(() => (index: number) => {
				const option = props.options[index];

				return option?.label ?? option?.value ?? '';
			})
		};
	},

	computed: {
		isChecked(): (i: number) => boolean {
			return index => {
				const option = this.options[index];

				return option?.value === this.modelValue;
			};
		},

		isAnySelected(): boolean {
			return this.modelValue != null;
		},

		optionalLabelAttributes(): (index: number) => AnyObject {
			return index => {
				const field = this.options[index];
				const attrs: AnyObject = {};

				if (field.slug) {
					attrs['id'] = field.slug;
				}

				return attrs;
			};
		}
	}
});
