<template>
	<router-view v-slot="{ Component }">
		<div class="layout-system">
			<transition v-bind="layoutTransition">
				<div v-if="layout.component" :key="layoutKey" class="layout-system__layout">
					<component :is="layout.component" v-bind="{ ...layout.props }">
						<transition v-bind="viewTransition" name="route" mode="out-in">
							<div :key="viewKey" class="layout-system__view">
								<component :is="Component" />
							</div>
						</transition>
					</component>
				</div>
			</transition>
		</div>
	</router-view>
</template>

<script lang="ts">
import gsap, { Power1 } from 'gsap/all';
import { AsyncComponentLoader, Component, defineComponent, PropType } from 'vue';

import { VueTransitionProps } from '@/tools/types';

import { useLayoutSystem } from './composable';

export default defineComponent({
	props: {
		defaultLayout: {
			type: [Object, Function] as PropType<Component | AsyncComponentLoader>,
			required: true
		}
	},

	setup(props) {
		const { ...layoutSystem } = useLayoutSystem(props);

		const createDefaultTransition = (): VueTransitionProps => ({
			css: false,
			mode: 'out-in',

			onEnter: (el, done) => {
				gsap.fromTo(
					el,
					{ opacity: 0 },
					{
						opacity: 1,
						duration: 0.6,
						ease: Power1.easeOut,
						willChange: 'opacity',
						clearProps: 'all',
						onComplete: done
					}
				);
			},

			onLeave: (el, done) => {
				gsap.fromTo(
					el,
					{ opacity: 1 },
					{
						opacity: 0,
						duration: 0.6,
						ease: Power1.easeOut,
						willChange: 'opacity',
						clearProps: 'all',
						onComplete: done
					}
				);
			}
		});

		const layoutTransition: VueTransitionProps = createDefaultTransition();
		const viewTransition: VueTransitionProps = createDefaultTransition();

		return {
			...layoutSystem,

			layoutTransition,
			viewTransition
		};
	}
});
</script>

<style lang="scss">
.layout-system {
	@include max-phablet {
		width: 100%;
	}

	&,
	&__layout,
	&__view-wrapper,
	&__view {
		width: 100%;
		height: 100%;
	}
}
</style>
