import { defineMutations } from '@owlsdepartment/vuex-typed';

import { UserAnswerType } from '@/tools/types/basic';

import state from './state';

export default defineMutations(state, {
	// increment(state) {
	// 	state.counter += 1;
	// },

	SET_USER_ACTION(state, userAction: UserAnswerType) {
		const alreadyInArrayIndex = state.userActions.findIndex(x => {
			return Object.keys(x)[0] === Object.keys(userAction)[0];
		});

		if (alreadyInArrayIndex < 0) {
			state.userActions.push(userAction);
		} else {
			state.userActions[alreadyInArrayIndex] = userAction;
		}
	},
	REMOVE_USER_LAST_ACTION(state) {
		state.userActions.pop();
	},
	CLEAR_USER_ACTIONS(state) {
		state.userActions = [];
	}
});
