import { AnswerModel } from '@/models/AnswerModel';

import { QuestionnaireType } from './types';

const state = () => {
	return {
		type: '' as QuestionnaireType,
		answers: [] as AnswerModel[],
		currentQuestionIndex: 0
	};
};

export default state;
