
import { useFieldValue } from 'vee-validate';
import { defineComponent, PropType, reactive, unref, watch } from 'vue';

import { useField, ValidationRule } from '@/common/validation';

export const FormFieldProps = {
	name: {
		type: String,
		required: true
	},

	rules: {
		type: [String, Function, Array, Object] as PropType<ValidationRule>,
		default: () => []
	},

	modelValue: {
		default: null,
		validator: () => true
	},

	validateOnMount: Boolean,

	validateOnChange: {
		type: Boolean,
		default: true
	},

	validateOnInput: {
		type: Boolean,
		default: true
	},

	validateOnBlur: {
		type: Boolean,
		default: false
	}
} as const;

/**
 * Wrapper over form field, to enable validation.
 * To fully use it, field should implement one of:
 *   - `model:value`
 *   - `model:modelValue`
 *   - prop: `value` or `modelValue` and events `input` or `change`
 *
 * Optional events to enhance validation experience:
 *   - `input`
 *   - `change`
 *   - `blur`
 *
 * Usage example:
 * ```
 * <form-field v-slot="{ field }">
 *   <input v-bind="field" />
 * </form-field>
 * ```
 *
 * The same comes for custom components
 *
 * v-model can be bound to `FormField`, as a way of setting and getting current value
 */
export default defineComponent({
	props: {
		...FormFieldProps
	},

	emits: ['change', 'input', 'blur', 'update:modelValue'],

	setup(props, { emit }) {
		const currentValue = unref(useFieldValue(props.name));
		const { name, value, resetField, ...validation } = useField(props);

		// Making it reactive so we unwrap `value`
		const field = reactive({
			name,
			value,
			modelValue: value,
			'onUpdate:value': updateValue,
			'onUpdate:modelValue': updateValue,
			onChange: (...args: any[]) => {
				emit('change', ...args);
				filterCustomEvents(args[0], (e: unknown) =>
					validation.handleChange(e, props.validateOnChange)
				);
			},
			onInput: (...args: any[]) => {
				emit('input', ...args);
				filterCustomEvents(args[0], (e: unknown) =>
					validation.handleChange(e, props.validateOnInput)
				);
			},
			onBlur: (...args: any[]) => {
				emit('blur', ...args);

				filterCustomEvents(args[0], (e: unknown) =>
					props.validateOnBlur ? validation.handleChange(e) : validation.handleBlur(e as any)
				);
			}
		});

		value.value = currentValue;

		function updateValue(val: unknown) {
			value.value = val;
			if (props.validateOnChange || props.validateOnInput) {
				validation.validate();
			}
		}

		function filterCustomEvents(e: unknown, cb: CallableFunction) {
			e instanceof CustomEvent ? updateValue(e.detail) : cb(e);
		}

		watch(
			() => props.modelValue,
			() => {
				if (value.value !== props.modelValue) {
					updateValue(props.modelValue);
				}
			}
		);
		watch(
			value,
			curr => {
				emit('update:modelValue', curr);
			},
			{ immediate: true }
		);

		return {
			...validation,
			resetField,
			field
		};
	}
});
