<template>
	<base-back />
	<BaseButton secondary small class="button-prev-q button-prev-q--mobile" @click="onClickBack">
		<template #icon>
			<svg viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.54 1.708L3.691 6.557l4.849 4.848-1.496 1.497L.699 6.557 7.044.212 8.54 1.708z" fill="#fff"/></svg>
		</template>

		POPRZEDNI KROK
	</BaseButton>

	<div
		class="layout-question"
		:class="{
			'layout-question--date': currentQuestion?.type === 'date',
			'layout-question--email': currentQuestion?.type === 'email'
		}"
	>
		<slot />

		<footer class="layout-question__footer">
			<BaseButton secondary small class="button-prev-q button-prev-q--desktop" @click="onClickBack">
				<template #icon>
					<svg viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'arrow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.54 1.708L3.691 6.557l4.849 4.848-1.496 1.497L.699 6.557 7.044.212 8.54 1.708z" fill="#fff"/></svg>
				</template>

				POPRZEDNI KROK
			</BaseButton>

			<VLazyImage :src="logo" class="layout-question__footer-logo" alt="Vetoquinol logo" />
		</footer>
	</div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue';

import { useGetImage } from '@/composables';
import { questionsGetters, questionsMutations, questionsState } from '@/store/modules/questions';

export default defineComponent({
	props: {
		showBack: {
			type: Boolean,
			default: true
		}
	},

	setup() {
		const { getHomeImage } = useGetImage();

		return {
			logo: getHomeImage('logo-veto'),

			currentQuestionIndex: computed(questionsState.currentQuestionIndex),
			currentQuestion: computed(questionsGetters.currentQuestion)
		};
	},

	methods: {
		onClickBack() {
			if (this.$route.name === 'questions') {
				this.$router.push({ name: 'home' });
			} else if (this.currentQuestionIndex === 0) {
				this.$router.push({ name: 'questions' });
			} else {
				questionsMutations.setPrevCurrent();
			}
		}
	}
});
</script>

<style lang="scss">
.button-prev-q {
	&.button-prev-q--desktop {
		@include bp(1200) {
			display: none;
		}
	}

	&.button-prev-q--mobile {
		display: none;

		@include bp(1200) {
			display: inline-flex;
			position: fixed;
			top: 20px;
			right: 25px;
			z-index: $z_index_over_content;
		}
	}
}

.layout-question {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-rows: 1fr auto;
	width: 100%;
	min-height: 100vh;
	min-height: calc(var(--vh, 1vh) * 100);
	padding-bottom: calc(15.3rem + 19px);
	text-align: center;

	@include under-hd {
		padding-bottom: calc(13rem + 15px);
	}

	@include tablet-portrait {
		padding-bottom: 153px;

		&.layout-question--email {
			padding-bottom: 623px;
		}
	}

	@include max-phablet {
		padding-bottom: 143px;

		&.layout-question--email {
			padding-bottom: 373px;
		}
	}

	&__footer {
		@include flex-center-between;

		width: 100%;
		height: calc(15.3rem + 19px);
		padding: 1.5rem 6rem 1.5rem 8rem;
		position: fixed;
		bottom: 0;
		z-index: $z_index_content;
		border-top: 19px solid $color_green;
		background: $color_green;
		background: white;

		@include under-hd {
			height: 13rem;
			border-top-width: 15px;
		}

		@include bp(1200) {
			padding-left: 57px;
		}

		@include tablet-portrait {
			height: 153px;
			padding: 10px 38px 10px 32px;
		}

		@include max-phablet {
			justify-content: center;
			height: 143px;
			padding: 20px 15px 10px;
			border-top-width: 24px;
		}
	}

	&__footer-logo {
		margin: -11px 0 0;
		margin-left: auto;

		@include under-hd {
			max-height: 6.5rem;
		}

		@include tablet-portrait {
			min-height: 73px;
			max-height: 73px;
		}

		@include max-phablet {
			min-height: 62px;
			max-height: 62px;
			margin: -14px 0 0;
		}

		@include bp(1200) {
			margin-left: 0;
		}
	}
}
</style>
