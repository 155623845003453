import { LOADING_MODULE_KEY, loadingModule } from '@/common/loading';

import global from './global';
import questions from './questions';

export default {
	[LOADING_MODULE_KEY]: loadingModule,
	global,
	questions
};
