import { FieldContext } from 'vee-validate';
import { inject, InjectionKey, provide, shallowReactive, unref } from 'vue';

const registerFieldKey: InjectionKey<(field: FieldContext) => void> = Symbol();

export function useRegisterField(field: FieldContext) {
	const registerField = inject(registerFieldKey, null);

	registerField?.(field);
}

export function useFieldContextSystem() {
	const allFields = shallowReactive<Record<string, FieldContext | undefined>>({});

	const registerField = (field: FieldContext) => {
		allFields[unref(field.name)] = field;
	};

	provide(registerFieldKey, registerField);

	function getFieldContext(name: string) {
		return allFields[name];
	}

	return {
		getFieldContext
	};
}
