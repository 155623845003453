import { isString } from 'lodash';

import { ValidationFunction } from '@/common/validation';

export const noWhitespaceOnly: ValidationFunction = value => {
	if (!isString(value) || value === '') {
		return true;
	}

	const isValid = (value as string).trim() !== '';

	return isValid || 'The field cannot contain only white signs';
};
