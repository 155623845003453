<template>
	<form-field v-slot="{ field }" v-model="model" v-bind="$props">
		<base-input class="form-input" v-bind="{ ...field, ...$attrs }" />
	</form-field>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { useFormFieldWrapper } from '@/common/validation/composables';

import { FormFieldProps } from '../base/FormField.vue';

export default defineComponent({
	inheritAttrs: false,

	props: {
		...FormFieldProps,

		modelValue: {
			type: [String, Number],
			default: ''
		}
	},

	emit: ['update:modelValue'],

	setup(props, { emit }) {
		return {
			...useFormFieldWrapper(props, emit)
		};
	}
});
</script>
