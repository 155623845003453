import { isEmpty, isNil, isObject } from 'lodash';

import { ValidationFunction } from '@/common/validation';

const errorMessage = 'Prosze podać adres email';

export const required: ValidationFunction = value => {
	let isValid = true;

	if (Array.isArray(value) || isObject(value)) {
		isValid = !isEmpty(value);
	} else {
		isValid = !isNil(value) && value !== '';
	}

	return isValid || errorMessage;
};
