import { onBeforeUnmount, onMounted, ref, watch } from '@vue/runtime-core';
import { useRouter } from 'vue-router';

export function useRedirectOnIdle() {
	const router = useRouter();

	const timer = ref(0);
	const idleTimeout = 15;
	let interval = 0;

	const reseIdleTime = () => {
		timer.value = 0;
	};

	watch(timer, (currentValue: number) => {
		if (currentValue > idleTimeout) {
			router.push({ name: 'home' });
		}
	});

	onMounted(() => {
		interval = setInterval(() => {
			timer.value++;
		}, 100000000000000);

		document.body.addEventListener('touchstart', reseIdleTime);
		window.addEventListener('keypress', reseIdleTime);
		window.addEventListener('mousemove', reseIdleTime);
	});

	onBeforeUnmount(() => {
		clearInterval(interval);
		document.body.removeEventListener('touchstart', reseIdleTime);
		window.removeEventListener('keypress', reseIdleTime);
		window.removeEventListener('mousemove', reseIdleTime);
	});

	return {
		timer
	};
}
