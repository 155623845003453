<template>
	<component
		:is="buttonTag"
		class="base-button"
		:class="additionalClasses"
		:to="to"
		:type="buttonType"
		v-bind="optionalAttributes"
	>
		<span v-if="hasIcon" class="base-button__icon">
			<slot name="icon" />
		</span>

		<span v-if="hasContent && !iconOnly" class="base-button__text">
			<slot />
		</span>
	</component>
</template>

<script lang="ts">
import { Component, defineComponent, Prop, PropType } from 'vue';
import { RouteLocationRaw } from 'vue-router';

import { useHasSlot } from '@/composables';
import { enumValidator } from '@/tools/helpers';
import { AnyObject } from '@/tools/types';

import { ButtonSize, ButtonTag, ButtonType } from './types';

/**
 * If value is empty string, than class modificator will be of this prop's value, f. ex.:
 * { size: '' }
 *
 * class -> `base-button--${this.size}`
 */
const classToParams = {
	size: '',
	loading: 'is-loading',
	disabled: 'is-disabled',
	primary: 'primary',
	secondary: 'secondary',
	small: 'small',
	minimal: 'minimal',
	inline: 'inline',
	iconOnly: 'icon-only',
	uppercase: 'uppercase'
};

const getClassProps = () => {
	type ParamsKeys = keyof typeof classToParams;
	const props: Record<ParamsKeys, Prop<boolean>> = {} as any;

	for (const key of Object.keys(classToParams)) {
		props[key as ParamsKeys] = {
			type: Boolean
		};
	}

	return props;
};

export default defineComponent({
	props: {
		tag: {
			type: [String, Object] as PropType<ButtonTag>,
			default: 'button'
		},

		to: {
			type: [String, Object] as PropType<RouteLocationRaw>,
			default: null
		},

		type: {
			type: String as PropType<ButtonType>,
			default: 'button' as const,
			validator: enumValidator<ButtonType>(['button', 'submit', 'reset'])
		},

		...getClassProps(),

		size: {
			type: String as PropType<ButtonSize>,
			default: null
		}
	},

	setup(props, { slots }) {
		const hasIcon = useHasSlot('icon', slots);
		const hasContent = useHasSlot('default', slots);

		return {
			hasIcon,
			hasContent
		};
	},

	computed: {
		buttonTag(): string | Component {
			return this.to ? 'router-link' : this.tag;
		},

		buttonType(): ButtonType | false {
			return this.tag === 'button' ? this.type : false;
		},

		optionalAttributes(): AnyObject {
			const additional: AnyObject = {};

			if (this.type === 'button') {
				additional.disabled = this.disabled;
			}

			return additional;
		},

		additionalClasses(): Array<string> {
			const classes: string[] = [];

			for (const _param in classToParams) {
				const param = _param as keyof typeof classToParams;

				if (this[param]) {
					let className = classToParams[param];

					if (!className) {
						className = `${this[param]}`;
					}

					classes.push(`base-button--${className}`);
				}
			}

			return classes;
		}
	}
});
</script>

<style lang="scss">
.base-button {
	$self: &;

	@include font-jockey;
	@include inline-flex-center;

	min-width: 21.1rem;
	min-height: 7.1rem;
	padding: 1rem 2rem 0.5rem;
	color: inherit;
	color: white;
	font-size: 3rem;
	font-weight: 400;
	line-height: (44 / 30);
	text-decoration: none;
	transition: $transition_default;
	transition-property: background-color, transform, color;
	border: none;
	border-radius: 3px;
	background: $color_green;

	@include bp(800, $height: true) {
		min-height: 6.5rem;
	}

	@include tablet-portrait {
		min-width: 211px;
		min-height: 71px;
		font-size: 30px;
	}

	@include max-phablet {
		min-width: 214px;

		.question-structure__columns & {
			min-width: 100%;
		}
	}

	@include max-mobile {
		min-width: 174px;
		min-height: 50px;
		padding: 1rem 8px 0.5rem;
		font-size: 24px;
	}

	&:focus,
	&:active {
		background: $color_green;
	}

	&:hover {
		@include min-tablet {
			background: $color_green_dark;
		}
	}

	&--secondary {
		background: $color_cyan;

		&:focus,
		&:active {
			background: $color_cyan;
		}

		&:hover {
			@include min-tablet {
				background: $color_cyan_dark;
			}
		}
	}

	&--small {
		@include font-din-pro;

		min-width: 19.4rem;
		min-height: 5rem;
		font-size: 16px;
		line-height: (21 / 16);

		@include max-laptop {
			min-width: 195px;
			min-height: 50px;
			font-size: 16px;
			line-height: (21 / 16);
		}

		@include max-phablet {
			min-width: 174px;
			min-height: 40px;
			padding-right: 13px;
			padding-left: 11px;

			#{$self}__icon {
				margin-right: 11px;
			}
		}
	}

	&--uppercase {
		text-transform: uppercase;
	}

	&__text,
	&__icon {
		transition: inherit;
	}

	&__icon {
		margin-right: 1.4rem;

		@include max-laptop {
			margin-right: 15px;
		}

		.arrow {
			width: 9px;
			height: 13px;
		}
	}

	&--icon-only {
		#{$self}__icon {
			display: flex;
		}
	}

	&--is-disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	&--inline {
		display: inline;
		padding: 0;
		color: $color_primary;
		text-decoration: underline;
		background: transparent;

		&:hover {
			color: $color_green_dark;
			background: transparent;
		}
	}
}
</style>
