<template>
	<fieldset class="base-radio" :class="{ 'base-radio--is-checked': isAnySelected }">
		<legend v-if="hasLegend" class="base-radio__legend">
			<slot name="legend">
				{{ legend }}
			</slot>
		</legend>

		<div class="base-radio__wrapper">
			<label
				v-for="({ value }, index) in options"
				:key="index"
				class="base-radio__content"
				v-bind="optionalLabelAttributes(index)"
			>
				<input
					:id="`option_${index}`"
					v-model="model"
					class="base-radio__input visually-hidden"
					type="radio"
					:checked="isChecked(index)"
					v-bind="{ name, value }"
					@change="onChange"
				/>

				<span class="base-radio__dot" />

				<p class="base-radio__label">
					<slot name="label" v-bind="{ label: getLabel(index), value, index }">
						{{ getLabel(index) }}
					</slot>
				</p>
			</label>
		</div>
	</fieldset>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue';

import { useHasSlot, useVModelProxy } from '@/composables';
import { AnyObject } from '@/tools/types';

export type RadioValue = boolean | number | string;

export interface RadioOption {
	label?: string;
	value: RadioValue;
	slug?: string;
}

export default defineComponent({
	props: {
		/**
		 * Native input `name`
		 */
		name: {
			type: String,
			default: ''
		},
		/**
		 * Optional fieldset `legend`
		 */
		legend: {
			type: String,
			default: ''
		},
		/**
		 * v-model prop
		 */
		modelValue: {
			type: [Number, String, Boolean] as PropType<RadioValue>,
			default: ''
		},
		/**
		 * All radio options, rendered as inputs
		 */
		options: {
			type: Array as PropType<RadioOption[]>,
			default: () => [] as RadioOption[]
		}
	},

	emits: ['update:modelValue', 'change'],

	setup(props, { emit, slots }) {
		const model = useVModelProxy(props, emit);

		return {
			model,

			hasLegend: useHasSlot('legend', slots, slot => slot || !!props.legend),
			onChange: () => {
				emit('change', model.value);
			},
			getLabel: computed(() => (index: number) => {
				const option = props.options[index];

				return option?.label ?? option?.value ?? '';
			})
		};
	},

	computed: {
		isChecked(): (i: number) => boolean {
			return index => {
				const option = this.options[index];

				return option?.value === this.modelValue;
			};
		},

		isAnySelected(): boolean {
			return this.modelValue != null;
		},

		optionalLabelAttributes(): (index: number) => AnyObject {
			return index => {
				const field = this.options[index];
				const attrs: AnyObject = {};

				if (field.slug) {
					attrs['id'] = field.slug;
				}

				return attrs;
			};
		}
	}
});
</script>

<style lang="scss">
.base-radio {
	$self: &;

	&__wrapper {
		display: grid;
		gap: 1.5rem 3.2rem;
	}
}
</style>
