import { ActionContext } from '@owlsdepartment/vuex-typed';

// import { moduleDecorators } from '@/common/loading';
import { createStoreClassInstance } from '@/tools/decorators';
import { UserAnswerType } from '@/tools/types/basic';

import getters from './getters';
import mutations from './mutations';
import state from './state';

type Ctx = ActionContext<typeof state, typeof getters, typeof mutations>;

// const { loading } = moduleDecorators('global');

class Actions {
	addUserActions({ commit }: Ctx, action: UserAnswerType) {
		commit('SET_USER_ACTION', action);
	}
	removeLastUserAction({ commit }: Ctx) {
		commit('REMOVE_USER_LAST_ACTION');
	}
	clearUserActions({ commit }: Ctx) {
		commit('CLEAR_USER_ACTIONS');
	}
}

export default createStoreClassInstance(Actions);
