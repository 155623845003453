
import dayjs, { Dayjs } from 'dayjs';
import { isDate, isNull } from 'lodash';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

import Dropdown from '@/components/base/dropdown/Dropdown.vue';
import { useHasSlot } from '@/composables';
import { flagsToCssClass } from '@/tools/helpers';
import { VueClassBinding, VueClassBindingObject } from '@/tools/types';

import { useCalendarControls, useCalendarStructure } from './composables';

/**
 * TODO:
 *  - accessibility
 *  - range date picker
 */

export interface DayCellState {
	rowIndex: number;
	dayIndex: number;
	day: number;
	isEmpty: boolean;
	isDisabled: boolean;
	isEnabled: boolean;
	isSelected: boolean;
}

export interface DayCellStateWithRow extends DayCellState {
	readonly row: DayCellState[];
	readonly grid: DayCellState[][];
}

export type AdditionalClasses = (param: DayCellStateWithRow) => VueClassBindingObject;
export type DisableDay = (day: Dayjs) => boolean;

const dateValidator = (v: unknown): boolean => isDate(v) || isNull(v);

export default defineComponent({
	components: {
		Dropdown
	},

	props: {
		modelValue: {
			type: Object as PropType<Date>,
			default: null,
			validator: dateValidator
		},

		showDaysHeader: {
			type: Boolean
		},

		disableDay: {
			type: Function as PropType<DisableDay>,
			default: undefined
		},

		additionalClasses: {
			type: Function as PropType<AdditionalClasses>,
			default: undefined
		},

		highlighted: {
			type: Boolean
		},

		startingDate: {
			type: Object as PropType<Date>,
			default: null,
			validator: dateValidator
		},

		hasError: Boolean
	},

	emits: ['update:modelValue'],

	setup(props, { emit, slots }) {
		const root = ref<HTMLElement | null>(null);
		const { selectedDate, currentDateMoment, ...controls } = useCalendarControls(props);

		watch(selectedDate, () => {
			const value = selectedDate.value?.toDate() ?? null;

			emit('update:modelValue', value);
			root.value?.dispatchEvent(
				new CustomEvent('input', {
					bubbles: true,
					cancelable: false,
					detail: value
				})
			);
		});

		return {
			...controls,
			...useCalendarStructure(currentDateMoment),

			root,
			selectedDate,
			currentDateMoment,

			rootClasses: computed(() => {
				const flags = flagsToCssClass('base-datepicker', props, ['highlighted']);

				(flags as any)['base-datepicker--has-error'] = props.hasError;

				return flags;
			}),
			hasFooter: useHasSlot('footer', slots)
		};
	},

	computed: {
		weekDayNames(): Array<string> {
			return ['Po', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nie'];
		},

		allMonths(): Array<string> {
			return dayjs.months();
		},

		currentMonth(): any {
			const currentMonth = dayjs().month();
			return dayjs()
				.month(currentMonth)
				.format('MMMM');
		},

		dayCellsState(): DayCellState[][] {
			const state: DayCellState[][] = [];

			for (let rowIndex = 0; rowIndex < this.daysGrid.length; rowIndex++) {
				const row = this.daysGrid[rowIndex];

				state.push([]);

				for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
					const cell = row[cellIndex];
					const cellState = this.getDayCellState(cell, cellIndex, rowIndex);

					state[rowIndex].push(cellState);
				}
			}

			return state;
		},

		daysClasses(): VueClassBinding[][] {
			const classes: VueClassBinding[][] = [];

			for (const row of this.dayCellsState) {
				const classRow: VueClassBinding[] = [];

				for (const cell of row) {
					classRow.push([
						this.getInternalClasses(cell),
						this.getAdditionalClasses({ ...cell, row, grid: this.dayCellsState })
					]);
				}

				classes.push(classRow);
			}

			return classes;
		},

		getDayCellState() {
			return (day: number, dayIndex: number, rowIndex: number): DayCellState => {
				const isEmpty = this.isEmpty(day);
				const isDisabled = this.isDisabled(day);
				const emptyConditional = <T extends unknown>(val: T) => (isEmpty ? false : val);

				return {
					day,
					dayIndex,
					rowIndex,
					isEmpty,
					isDisabled: emptyConditional(isDisabled),
					isEnabled: emptyConditional(!isDisabled),
					isSelected: this.isSelected(day)
				};
			};
		},

		getInternalClasses() {
			return (dayCell: DayCellState): VueClassBindingObject => {
				if (dayCell.isEmpty) {
					return { 'base-datepicker__day--empty': true };
				}

				return {
					'base-datepicker__day--selected': dayCell.isSelected,
					'base-datepicker__day--is-disabled': dayCell.isDisabled,
					'base-datepicker__day--is-enabled': dayCell.isEnabled
				};
			};
		},

		getAdditionalClasses() {
			return (dayCell: DayCellStateWithRow): VueClassBindingObject => ({
				...this.getProjectSpecificClasses(dayCell),
				...(this.additionalClasses?.(dayCell) ?? {})
			});
		}
	},

	methods: {
		isDisabled(day: number): boolean {
			if (this.isEmpty(day) || !this.disableDay) {
				return false;
			}

			return this.disableDay(dayjs(this.currentDateMoment).date(day));
		},

		getProjectSpecificClasses(dayCell: DayCellStateWithRow): VueClassBindingObject {
			const { dayIndex, row, isEnabled } = dayCell;

			if (!isEnabled || !this.disableDay) {
				return {};
			}

			const firstEnabledInRow = row.findIndex(({ isEnabled }) => isEnabled);
			const lastEnabledInRow = [...row].reverse().find(({ isEnabled }) => isEnabled)?.dayIndex;

			return {
				'base-datepicker__day--first-in-row-enabled': dayIndex === firstEnabledInRow,
				'base-datepicker__day--last-in-row-enabled': dayIndex === lastEnabledInRow
			};
		}
	}
});
