
import { computed, defineComponent, PropType } from 'vue';

import TextAccordion from '@/components/TextAccordion.vue';
import { useHasSlot, useVModelProxy } from '@/composables';
import { AnyObject } from '@/tools/types';

export type CheckboxValue = boolean | number | string;

export interface CheckboxOption {
	label?: string;
	value: CheckboxValue;
	slug?: string;
	disabled?: boolean;
}

export default defineComponent({
	components: {
		TextAccordion
	},

	props: {
		/**
		 * Native input `name`
		 */
		name: {
			type: String,
			default: ''
		},
		/**
		 * Optional fieldset `legend`
		 */
		legend: {
			type: String,
			default: ''
		},
		/**
		 * v-model prop
		 */
		modelValue: {
			type: [Array, Boolean] as PropType<CheckboxValue[] | boolean>,
			default: () => []
		},
		/**
		 * All checkbox options, rendered as inputs
		 */
		options: {
			type: Array as PropType<CheckboxOption[]>,
			default: () => [] as CheckboxOption[]
		},
		/**
		 * Display checkbox inputs in two columns
		 * Maybe change to `columns: number;`?
		 */
		columns: {
			type: Number,
			default: 1,
			validator: (val: number) => val >= 1
		},

		bigger: Boolean,

		border: Boolean,

		single: Boolean,

		hasError: Boolean
	},

	emits: ['update:modelValue'],

	setup(props, { emit, slots }) {
		const model = useVModelProxy(props, emit);
		if (props.single && Array.isArray(props.modelValue)) {
			model.value = false;
		}

		return {
			model,
			getLabel: computed(() => (index: number) => {
				const option = props.options[index];

				return option?.label ?? option?.value ?? '';
			}),
			hasLegend: useHasSlot('legend', slots, slot => slot || !!props.legend)
		};
	},

	computed: {
		checkboxOptions(): CheckboxOption[] {
			return this.single ? [this.options[0]] : this.options;
		},

		isChecked(): (i: number) => boolean {
			return index => {
				if (Array.isArray(this.model)) {
					return this.model.includes(this.options[index].value);
				}

				return this.model;
			};
		},

		isAnySelected(): boolean {
			return Array.isArray(this.model) ? this.model.length !== 0 : this.model;
		},

		inputOptionalAttributes(): (index: number) => AnyObject {
			return index => {
				const attrs: AnyObject = {};
				const { slug, disabled } = this.options[index];

				if (slug) {
					attrs['id'] = slug;
				}

				if (disabled) {
					attrs['disabled'] = disabled;
				}

				return attrs;
			};
		},

		tag(): string {
			return this.hasLegend ? 'fieldset' : 'div';
		}
	}
});
