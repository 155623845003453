import { computed, reactive, Ref, ref, shallowRef, toRefs, watch } from '@vue/runtime-core';
import dayjs, { Dayjs } from 'dayjs';
import pl from 'dayjs/locale/pl';
import localeData from 'dayjs/plugin/localeData';
import { zipWith } from 'lodash';

export interface HeaderDay {
	abbr: string;
	short: string;
}

const EMPTY_DAY = -1;

dayjs.extend(localeData);
dayjs.locale({ ...pl, weekStart: 1 });

/**
 * Get variables and methods that represent current state of a calendar
 * @param props
 * @returns
 */
export const useCalendarControls = (props: {
	modelValue: Date | null;
	startingDate: Date | null;
}) => {
	const currentDate = ref(
		props.startingDate?.toISOString() ?? props.modelValue?.toISOString() ?? dayjs().toISOString()
	);

	const state = reactive({
		currentDate,
		yearsToPick: computed(() => {
			let currentYear = dayjs().year();
			const yearTo = currentYear - 4;
			const years = [];
			years.push(currentYear);

			while (currentYear > yearTo) {
				currentYear = currentYear - 1;
				years.push(currentYear);
			}
			return years;
		}),
		currentYear: computed(() => {
			return dayjs(currentDate.value).year();
		}),
		monthNames: computed(() => dayjs.months()),
		currentDateMoment: computed(() => dayjs(currentDate.value)),
		selectedDate: shallowRef<Dayjs | null>(!props.modelValue ? null : dayjs(props.modelValue)),
		isSelected: computed(() => (day: number) => {
			const date = dayjs(state.currentDateMoment).date(day);

			return !!state.selectedDate?.isSame(date, 'day');
		})
	});

	function selectDay(day: number) {
		state.selectedDate = dayjs(currentDate.value).date(day);
	}

	watch(
		() => props.modelValue,
		() => {
			if (props.modelValue?.toISOString() !== state.selectedDate?.toISOString()) {
				state.selectedDate = !props.modelValue ? null : dayjs(props.modelValue);
			}
		}
	);

	return {
		...toRefs(state),

		previousMonth: () => {
			currentDate.value = dayjs(currentDate.value)
				.subtract(1, 'month')
				.toISOString();
		},

		resetCurrentDate: () => {
			currentDate.value = '';
		},

		nextMonth: () => {
			currentDate.value = dayjs(currentDate.value)
				.add(1, 'month')
				.toISOString();
		},

		onMonthUpdate: (month: any) => {
			const monthNr = dayjs.months().indexOf(month);
			currentDate.value = dayjs(currentDate.value)
				.month(monthNr)
				.toISOString();
		},

		onYearUpdate: (year: any) => {
			currentDate.value = dayjs(currentDate.value)
				.year(year)
				.toISOString();
		},

		onDayClicked: (day: number) => {
			if (state.isSelected(day)) {
				state.selectedDate = null;
			} else {
				selectDay(day);
			}
		},

		isEmpty: (day: number) => day === EMPTY_DAY
	};
};

const fillEmptyCells = (row: number[]) => {
	for (let i = 0; i < row.length; i++) {
		if (!row[i]) {
			row[i] = EMPTY_DAY;
		}
	}
};

/**
 * Get reactive grid of days, based on `currentDate`
 * @param currentDate Ref to date that selects year and month, based on which grid is generated
 */
export const useCalendarStructure = (currentDate: Ref<Dayjs>) => {
	// TODO: support days outside of current month (currently: empty cells)
	const months = dayjs.months();
	const daysGrid = computed((): number[][] => {
		const rows: number[][] = [[]];
		const daysInMonth = currentDate.value.daysInMonth();
		const tmpMoment = dayjs(currentDate.value).date(1);
		const weekDay = tmpMoment.day() - 1 === -1 ? 6 : tmpMoment.day() - 1;

		rows[0][weekDay] = 1;
		fillEmptyCells(rows[0]);

		for (let date = 2, currentRow = 0; date <= daysInMonth; date++) {
			if (rows[currentRow].length === 7) {
				rows.push([]);
				currentRow++;
			}
			rows[currentRow].push(date);
		}

		rows[rows.length - 1].length = 7;
		fillEmptyCells(rows[rows.length - 1]);

		return rows;
	});
	const heading = computed(() => {
		const date = currentDate.value;

		return `${months[date.month()]} ${date.year()}`;
	});
	const headerDays: HeaderDay[] = zipWith(
		dayjs.weekdays(),
		dayjs.weekdaysShort(),
		(abbr, short) => ({
			abbr,
			short
		})
	);

	return {
		heading,
		headerDays,
		daysGrid
	};
};
