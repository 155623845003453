import { defineGetters } from '@owlsdepartment/vuex-typed';

import { UserAnswerType } from '@/tools/types/basic';

import state from './state';

export default defineGetters(state, {
	getUserActions: (state): UserAnswerType[] => {
		return state.userActions;
	}
});
