<template>
	<form-field v-slot="{ field }" v-model="model" v-bind="$props">
		<base-textarea class="form-textarea" v-bind="{ ...field, ...$attrs }" />
	</form-field>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import { ValidationRule } from '@/common/validation/helpers';
import { useVModelProxy } from '@/composables';

export default defineComponent({
	inheritAttrs: false,

	props: {
		name: {
			type: String,
			required: true
		},

		rules: {
			type: [String, Function, Array, Object] as PropType<ValidationRule>,
			default: () => []
		},

		validateOnMount: {
			type: Boolean
		},

		modelValue: {
			type: [String, Number],
			default: ''
		}
	},

	emit: ['update:modelValue'],

	setup(props, { emit }) {
		return {
			model: useVModelProxy(props, emit)
		};
	}
});
</script>

<style lang="scss">
.form-textarea {
	@include form-input;

	min-height: 24.5rem;
	resize: none;
}
</style>
