<template>
	<div ref="root" class="base-datepicker" :class="rootClasses">
		<div class="base-datepicker__header">
			<Dropdown :selected="currentMonth" :values="monthNames" @update="onMonthUpdate" />
			<Dropdown :selected="currentYear" :values="yearsToPick" transparent @update="onYearUpdate" />
		</div>

		<div class="base-datepicker__picker" role="grid" aria-labelledby="id-dialog-label">
			<div
				v-if="showDaysHeader"
				class="base-datepicker__days-header base-datepicker__row"
				role="row"
			>
				<div
					v-for="{ abbr, short } in headerDays"
					:key="short"
					scope="col"
					role="gridcell"
					v-bind="{ abbr }"
				>
					{{ short }}
				</div>
			</div>

			<div class="base-datepicker__day-names">
				<div v-for="dayName in weekDayNames" :key="dayName" class="base-datepicker__day-name">
					{{ dayName }}
				</div>
			</div>

			<div
				v-for="(row, rowIndex) in dayCellsState"
				:key="rowIndex"
				class="base-datepicker__row"
				role="row"
			>
				<div
					v-for="(dayCell, dayIndex) in row"
					:key="dayIndex"
					class="base-datepicker__day"
					:class="daysClasses[rowIndex][dayIndex]"
					role="gridcell"
				>
					<button
						v-if="!dayCell.isEmpty"
						class="base-datepicker__day-button"
						:disabled="dayCell.isDisabled"
						tabindex="-1"
						type="button"
						@click="onDayClicked(dayCell.day)"
					>
						{{ dayCell.day }}
					</button>
				</div>
			</div>

			<div
				v-if="dayCellsState.length < 5"
				class="base-datepicker__row base-datepicker__row--spacer"
				role="row"
			>
				<div class="base-datepicker__day" role="gridcell">
					<button class="base-datepicker__day-button" tabindex="-1" type="button">&nbsp;</button>
				</div>
			</div>

			<div v-if="hasFooter" class="base-datepicker__footer">
				<slot name="footer" />
			</div>

			<slot name="bottom" />
		</div>
	</div>
</template>

<script lang="ts">
import dayjs, { Dayjs } from 'dayjs';
import { isDate, isNull } from 'lodash';
import { computed, defineComponent, PropType, ref, watch } from 'vue';

import Dropdown from '@/components/base/dropdown/Dropdown.vue';
import { useHasSlot } from '@/composables';
import { flagsToCssClass } from '@/tools/helpers';
import { VueClassBinding, VueClassBindingObject } from '@/tools/types';

import { useCalendarControls, useCalendarStructure } from './composables';

/**
 * TODO:
 *  - accessibility
 *  - range date picker
 */

export interface DayCellState {
	rowIndex: number;
	dayIndex: number;
	day: number;
	isEmpty: boolean;
	isDisabled: boolean;
	isEnabled: boolean;
	isSelected: boolean;
}

export interface DayCellStateWithRow extends DayCellState {
	readonly row: DayCellState[];
	readonly grid: DayCellState[][];
}

export type AdditionalClasses = (param: DayCellStateWithRow) => VueClassBindingObject;
export type DisableDay = (day: Dayjs) => boolean;

const dateValidator = (v: unknown): boolean => isDate(v) || isNull(v);

export default defineComponent({
	components: {
		Dropdown
	},

	props: {
		modelValue: {
			type: Object as PropType<Date>,
			default: null,
			validator: dateValidator
		},

		showDaysHeader: {
			type: Boolean
		},

		disableDay: {
			type: Function as PropType<DisableDay>,
			default: undefined
		},

		additionalClasses: {
			type: Function as PropType<AdditionalClasses>,
			default: undefined
		},

		highlighted: {
			type: Boolean
		},

		startingDate: {
			type: Object as PropType<Date>,
			default: null,
			validator: dateValidator
		},

		hasError: Boolean
	},

	emits: ['update:modelValue'],

	setup(props, { emit, slots }) {
		const root = ref<HTMLElement | null>(null);
		const { selectedDate, currentDateMoment, ...controls } = useCalendarControls(props);

		watch(selectedDate, () => {
			const value = selectedDate.value?.toDate() ?? null;

			emit('update:modelValue', value);
			root.value?.dispatchEvent(
				new CustomEvent('input', {
					bubbles: true,
					cancelable: false,
					detail: value
				})
			);
		});

		return {
			...controls,
			...useCalendarStructure(currentDateMoment),

			root,
			selectedDate,
			currentDateMoment,

			rootClasses: computed(() => {
				const flags = flagsToCssClass('base-datepicker', props, ['highlighted']);

				(flags as any)['base-datepicker--has-error'] = props.hasError;

				return flags;
			}),
			hasFooter: useHasSlot('footer', slots)
		};
	},

	computed: {
		weekDayNames(): Array<string> {
			return ['Po', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nie'];
		},

		allMonths(): Array<string> {
			return dayjs.months();
		},

		currentMonth(): any {
			const currentMonth = dayjs().month();
			return dayjs()
				.month(currentMonth)
				.format('MMMM');
		},

		dayCellsState(): DayCellState[][] {
			const state: DayCellState[][] = [];

			for (let rowIndex = 0; rowIndex < this.daysGrid.length; rowIndex++) {
				const row = this.daysGrid[rowIndex];

				state.push([]);

				for (let cellIndex = 0; cellIndex < row.length; cellIndex++) {
					const cell = row[cellIndex];
					const cellState = this.getDayCellState(cell, cellIndex, rowIndex);

					state[rowIndex].push(cellState);
				}
			}

			return state;
		},

		daysClasses(): VueClassBinding[][] {
			const classes: VueClassBinding[][] = [];

			for (const row of this.dayCellsState) {
				const classRow: VueClassBinding[] = [];

				for (const cell of row) {
					classRow.push([
						this.getInternalClasses(cell),
						this.getAdditionalClasses({ ...cell, row, grid: this.dayCellsState })
					]);
				}

				classes.push(classRow);
			}

			return classes;
		},

		getDayCellState() {
			return (day: number, dayIndex: number, rowIndex: number): DayCellState => {
				const isEmpty = this.isEmpty(day);
				const isDisabled = this.isDisabled(day);
				const emptyConditional = <T extends unknown>(val: T) => (isEmpty ? false : val);

				return {
					day,
					dayIndex,
					rowIndex,
					isEmpty,
					isDisabled: emptyConditional(isDisabled),
					isEnabled: emptyConditional(!isDisabled),
					isSelected: this.isSelected(day)
				};
			};
		},

		getInternalClasses() {
			return (dayCell: DayCellState): VueClassBindingObject => {
				if (dayCell.isEmpty) {
					return { 'base-datepicker__day--empty': true };
				}

				return {
					'base-datepicker__day--selected': dayCell.isSelected,
					'base-datepicker__day--is-disabled': dayCell.isDisabled,
					'base-datepicker__day--is-enabled': dayCell.isEnabled
				};
			};
		},

		getAdditionalClasses() {
			return (dayCell: DayCellStateWithRow): VueClassBindingObject => ({
				...this.getProjectSpecificClasses(dayCell),
				...(this.additionalClasses?.(dayCell) ?? {})
			});
		}
	},

	methods: {
		isDisabled(day: number): boolean {
			if (this.isEmpty(day) || !this.disableDay) {
				return false;
			}

			return this.disableDay(dayjs(this.currentDateMoment).date(day));
		},

		getProjectSpecificClasses(dayCell: DayCellStateWithRow): VueClassBindingObject {
			const { dayIndex, row, isEnabled } = dayCell;

			if (!isEnabled || !this.disableDay) {
				return {};
			}

			const firstEnabledInRow = row.findIndex(({ isEnabled }) => isEnabled);
			const lastEnabledInRow = [...row].reverse().find(({ isEnabled }) => isEnabled)?.dayIndex;

			return {
				'base-datepicker__day--first-in-row-enabled': dayIndex === firstEnabledInRow,
				'base-datepicker__day--last-in-row-enabled': dayIndex === lastEnabledInRow
			};
		}
	}
});
</script>

<style lang="scss">
.base-datepicker {
	$self: &;

	width: 100%;
	max-width: 464px;
	margin: 0 auto;
	padding: 2.4rem 0 4.7rem;
	position: relative;
	z-index: $z_index_content;
	transition: $transition_default;
	transition-property: border-color;
	border: 2px solid transparent;
	border-radius: 3px;
	background: white;

	@include bp(800, $height: true) {
		padding: 25px 0;
	}

	@include tablet-portrait {
		padding: 24px 0 47px;
	}

	@include max-mobile {
		padding: 18px 0 36px;
	}

	&--has-error {
		border-color: $color_error_border;
	}

	// buttons reset
	&__day-button,
	&__previous-month,
	&__next-month {
		padding: 0;
		color: inherit;
		border: none;
		outline: none;
		background: transparent;
	}

	&__day {
		#{$self}__day-button {
			@include inline-flex-center;

			width: 100%;
			min-height: 4rem;
			color: black;
			font-size: 13px;
			transition: $transition_default;
			transition-property: background-color, color;
			border-radius: 30px;

			@include max-desktop {
				min-height: 34px;
			}

			@include tablet-portrait {
				min-height: 40px;
			}

			@include max-mobile {
				min-height: 42px;
			}

			&:hover {
				color: $color_cyan;
				font-weight: 600;
				background-color: $color_gray_lighter;
			}
		}

		&#{$self}__day--selected {
			#{$self}__day-button {
				color: white;
				font-weight: 600;
				background: $color_green;
			}
		}
	}

	&__day-names {
		display: grid;
		grid-auto-columns: 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		width: 100%;
		margin: 15px auto;
		padding: 0 31px;
		font-size: 13px;
		font-weight: 600;
		gap: 0 0;

		@include max-desktop {
			padding: 0 20px;
		}

		@include tablet-portrait {
			padding: 0 31px;
		}

		@include max-mobile {
			margin: 17px auto;
			padding: 0 13px;
		}
	}

	&__row {
		display: grid;
		grid-auto-columns: 1fr;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		width: 100%;
		margin: 0 auto;
		padding: 0 31px;
		gap: 0 0;

		@include max-desktop {
			padding: 0 20px;
		}

		@include tablet-portrait {
			padding: 0 31px;
		}

		@include max-mobile {
			padding: 0 15px;
		}

		&#{$self}__row--spacer {
			visibility: hidden;
		}
	}

	&__footer {
		margin: 2.5rem auto 0;
		padding: 0 20px;

		@include max-desktop {
			margin: 20px auto 0;
		}

		@include tablet-portrait {
			margin: 32px auto 0;
		}

		@include max-mobile {
			margin-top: 24px;
			padding: 0 15px;
			text-align: left;
		}

		.base-checkbox {
			display: inline-flex;

			.base-checkbox__label {
				@include max-mobile {
					text-align: left;
				}
			}
		}
	}
}
</style>
