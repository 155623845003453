import { PickKeys } from '@/tools/types';

export function flagsToCssClass<
	Prefix extends string,
	Base extends Record<string, unknown>,
	Flag extends Extract<PickKeys<Base, boolean>, string>,
	// eslint-disable-next-line prettier/prettier
	ClassName extends `${Prefix}--is-${Flag}`
>(prefix: Prefix, object: Base, flags: Flag[]): { [key in ClassName]: boolean } {
	const cssClass: Partial<Record<ClassName, boolean>> = {};

	for (const flag of flags) {
		const key = `${prefix}--is-${flag}` as const;

		(cssClass as any)[key] = (object[flag] as any) as boolean;
	}

	return cssClass as { [key in ClassName]: boolean };
}
