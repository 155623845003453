import { createRouteNode } from '@owlsdepartment/vue-tree-routes';

import ViewHome from '@/views/ViewHome.vue';

const ViewNotFound = () =>
	import(/* webpackChunkName: "ViewNotFound" */ '@/views/ViewNotFound.vue');

export default createRouteNode({
	shared: {
		basePath: '/'
	},

	routes: [
		{
			path: '',
			name: 'home',
			component: ViewHome
		},
		{
			path: '/:pathMatch(.*)*',
			name: '404',
			component: ViewNotFound
		}
	]
});
