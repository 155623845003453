import { DirectiveBinding } from 'vue';

import { isDefined } from '@/tools/helpers/isDefined';

interface InviewBinding {
	effect: string;
	delay?: string;
}
export const inviewDirective = {
	mounted(el: HTMLElement, binding: DirectiveBinding<InviewBinding>) {
		const { effect, delay } = binding.value;
		el.dataset.inViewport = effect;
		if (isDefined(delay)) {
			el.dataset.inViewportDelay = delay;
		}
		const observer = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add('in-viewport');
					observer.disconnect();
				}
			});
		});
		if (observer) {
			observer.observe(el);
		}
	}
};
